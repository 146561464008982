export default {
    route: {
        dashboard: 'Panel',
        article: 'Artigos',
        stationTitle:
            'Datos da estación: ##COMPANY_BRAND_NAME## - Recarga rápida de vehículos eléctricos no Reino Unido',
        or: 'OU',
    },
    month: {
        jan: 'Xan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Abr',
        may: 'Mai',
        jun: 'Xuñ',
        jul: 'Xul',
        aug: 'Ago',
        sep: 'Set',
        oct: 'Out',
        nov: 'nov',
        dec: 'Dec',
    },
    error: {
        accessDenied: 'Acceso denegado',
        somethingWrong: 'Produciuse un erro',
        notFound: 'Non se atopou a páxina',
    },
    navbar: {
        title: '##COMPANY_BRAND_NAME##',
        logOut: 'Pechar sesión',
        myProfile: 'O meu perfil',
        termsConditions: 'Termos e condicións',
        menu: {
            dashboard: 'Panel',
            paymentConfiguration: 'Configuración de pagamento',
            chargingPaymentHistory: 'Historial de carga e pagamento',
            rewards: 'Recompensas',
            rfidCards: 'Tarxetas RFID',
        },
    },
    login: {
        title: 'Iniciar sesión: ##COMPANY_BRAND_NAME## Recarga rápida de vehículos eléctricos no Reino Unido',
        header: 'Dámoslle a benvida a',
        form: {
            email: 'ID de correo electrónico',
            password: 'Contrasinal',
        },
        placeholder: {
            region: 'Seleccionar rexión',
            language: 'Seleccionar idioma',
        },
        button: {
            submit: 'Iniciar sesión',
            forgotPassword: 'Esqueceu o contrasinal?',
            signup: 'Rexistrarse',
            signin: 'Iniciar sesión',
        },
        label: {
            logo: '##COMPANY_BRAND_NAME##',
            thirdPartyLogin: 'Ou inicie sesión con',
            rememberMe: 'Lembrarme',
            newAccount: 'Non ten conta?',
            facebook: 'Iniciar sesión con Facebook',
            google: 'Iniciar sesión con Google',
            apple: 'Iniciar sesión con Apple',
            copyright: 'Dereitos de autor ##COMPANY_BRAND_NAME## Ltd',
        },
        toast: {
            success: 'Inicio de sesión de condutor correcto.',
            inValid: 'Introduza o correo electrónico e o contrasinal correctos.',
            error: 'Produciuse un erro ao procesar a súa solicitude. Ténteo de novo máis tarde.',
            cancelled: 'A cancelación da súa conta está en proceso, polo que non poderá realizar ningunha acción.',
            maximumAttempt: 'Acadou o número máximo de intentos. Ténteo de novo máis tarde.',
            reminderMessage:
                'Engada unha tarxeta de pagamento na súa conta de crédito ou diñeiro á conta de prepagamento para activar o pagamento asociado a este código promocional.',
        },
        errors: {
            inValidEmail: 'O enderezo de correo electrónico non é válido.',
        },
    },
    forgot: {
        title: 'Esqueceu o seu contrasinal - ##COMPANY_BRAND_NAME##: Recarga rápida de vehículos eléctricos no Reino Unido',
        resetTitle:
            'Restableza o seu contrasinal - ##COMPANY_BRAND_NAME##: Recarga rápida de vehículos eléctricos no Reino Unido',
        header: 'Esqueceu o seu contrasinal?',
        subHeader:
            'Introduza a seguir o seu correo electrónico e enviarémoslle unha mensaxe para restablecer o seu contrasinal',
        resetHeader: 'Restableza o seu contrasinal.',
        resetSubHeader: 'Introduza o código que lle enviamos por correo electrónico e restableza o seu contrasinal.',
        supportNumber: 'O noso número de asistencia',
        button: {
            back: 'Volver',
            submit: 'Restablecer o meu contrasinal',
            save: 'Gardar o novo contrasinal',
        },
        form: {
            email: 'Correo electrónico',
            code: 'Código',
            password: 'Contrasinal',
            confirmPassword: 'Volver confirmar o contrasinal',
            message:
                'Se ten unha conta válida debería recibir un código por correo electrónico. Teña en conta que se se rexistrou nunha conta de ##COMPANY_BRAND_NAME## cunha conta federada, por exemplo, Apple, Google ou Facebook, non poderá restablecer o seu contrasinal, xa que está xestionada polo servizo de terceiros.  Se precisa máis axuda, póñase en contacto connosco mediante',
        },
        toast: {
            success: 'O seu contrasinal modificouse con éxito.',
            error: 'Produciuse un erro ao procesar a súa solicitude. Ténteo de novo máis tarde.',
            inValidEmailAddress: 'O enderezo de correo electrónico non é válido.',
            invalidCode: 'Vaites! O seu código non é válido.',
        },
        errors: {
            passwordFormat:
                'O contrasinal debe ter máis de 10 caracteres, con polo menos unha letra maiúscula, unha letra minúscula, un carácter especial e un número.',
            confirmPasswordMatch: 'o contrasinal de confirmación debe coincidir co novo contrasinal que introduciu.',
            verificationCodeFormat: 'Introduza o código de verificación de 6 díxitos.',
        },
    },
    dashboard: {
        title: 'Panel - ##COMPANY_BRAND_NAME##. Recarga rápida de vehículos eléctricos no Reino Unido',
        header: 'Panel',
        premiumSubscription:
            '##COMPANY_BRAND_NAME## ofrece un servizo Premium. Seleccione Subscribirme para gozar das funcións do servizo Premium gratis durante 3 meses.',
    },
    permission: {
        createArticle: 'Engadir artigo',
        editArticle: 'Editar artigo',
        save: 'Gardar',
        confirm: 'Está seguro/a?',
        cancel: 'Cancelar',
        update: 'Actualizar',
    },
    profile: {
        title: 'Perfil - ##COMPANY_BRAND_NAME##: Recarga rápida de vehículos eléctricos no Reino Unido',
        header: 'Perfil',
        addresses: 'Enderezos',
        personalDetails: {
            header: 'Datos persoais',
            label: {
                firstName: 'Nome',
                lastName: 'Apelidos',
                email: 'ID de correo electrónico',
                address: 'Enderezo',
                billingAddress: 'Enderezo de facturación',
                postcode: 'Código postal',
                samePersonalAddress: 'Igual que o enderezo persoal',
            },
            placeholder: {
                firstName: 'Nome',
                lastName: 'Apelidos',
                email: 'ID de correo electrónico',
                address: 'Introduza o seu enderezo',
                billingAddress: 'Enderezo de facturación',
                postcode: 'Código postal',
                searchAddress: 'Comece a escribir o seu enderezo para buscar',
            },
            button: {
                save: 'Gardar',
                cancel: 'Cancelar',
                yes: 'Si',
                no: 'Non',
            },
            errors: {
                email: {
                    email: 'O enderezo de correo electrónico non é válido.',
                },
                first_name: {
                    matches: 'O nome non é válido.',
                },
                last_name: {
                    matches: 'Os apelidos non son válidos.',
                },
                address: {
                    address: 'Requírese un mínimo de ##MIN_LENGTH## caracteres',
                    invalid: 'O enderezo non é válido',
                },
                billing_Address: {
                    billing_Address: 'Requírese un mínimo de ##MIN_LENGTH## caracteres',
                    invalid: 'O enderezo de facturación non é válido',
                },
            },
            model: {
                header: 'Buscar enderezo',
                address: 'Escribir enderezo',
                suggested_address: 'Enderezos suxeridos',
                no_data_found: 'Non se atoparon enderezos',
                no_address_found: 'Non se atopou ningún enderezo.',
                button: {
                    ok: 'Aceptar',
                    cancel: 'Cancelar',
                    close: 'Pechar',
                },
                toast: {
                    invalidAddress: 'Introduciuse un código postal ou un enderezo non válidos.',
                },
            },
        },
        addressDetails: {
            header: 'Enderezo',
            label: {
                address1: 'Enderezo',
                address2: 'Liña 2 de enderezo',
                city: 'Cidade',
                county: 'Condado',
                paymentPostcode: 'Código postal',
            },
            placeholder: {
                address1: 'Enderezo',
                address2: 'Liña 2 de enderezo',
                city: 'Cidade',
                county: 'Condado',
            },
        },
        billingAddressDetails: {
            header: 'Enderezo de facturación',
            label: {
                billingaddress1: 'Enderezo',
                billingaddress2: 'Liña 2 de enderezo',
                billingcity: 'Cidade',
                billingcounty: 'Condado',
                samePersonalAddress: 'Igual que o enderezo persoal',
            },
            placeholder: {
                billingaddress1: 'Enderezo',
                billingaddress2: 'Liña 2 de enderezo',
                billingcity: 'Cidade',
                billingcounty: 'Condado',
            },
        },
        marketingPreferenceDetails: {
            header: 'Preferencias de márketing',
            label: {
                receivePreference: 'Recibir comunicacións de márketing de ##COMPANY_BRAND_NAME##',
                yes: 'Si',
                no: 'Non',
            },
            message: 'Quere recibir comunicacións de márketing de ##COMPANY_BRAND_NAME##?',
        },
        langPreference: 'Preferencias de idioma',
        changePassword: {
            header: 'Contrasinal',
            toast: {
                success: 'O seu contrasinal modificouse con éxito.',
                error: 'Vaites! O seu contrasinal actual é incorrecto.',
                commonError: 'Produciuse un erro ao procesar a súa solicitude. Ténteo de novo máis tarde.',
            },
            button: {
                update: 'Actualizar',
            },
            label: {
                current_password: 'Contrasinal actual',
                new_password: 'Novo contrasinal',
                confirm_password: 'Volver confirmar o contrasinal',
            },
            placeholder: {
                current_password: 'Contrasinal actual',
                new_password: 'Novo contrasinal',
                confirm_password: 'Volver confirmar o contrasinal',
            },
            errors: {
                passwordFormat:
                    'O contrasinal debe ter máis de 10 caracteres, con polo menos unha letra maiúscula, unha letra minúscula, un carácter especial e un número.',
                confirmPasswordMatch:
                    'O contrasinal de confirmación debe coincidir co novo contrasinal que introduciu.',
            },
        },
        cancelAccount: {
            header: 'Eliminar conta',
            note1: 'Pode eliminar a súa conta en calquera momento. ##COMPANY_BRAND_NAME## eliminará todos os seus datos do noso sistema.',
            note2: 'Para eliminar a súa conta.',
            clickHere: 'Faga clic aquí',
            toast: {
                success:
                    'Lamentamos que marche. Iniciamos o proceso de eliminación da súa conta e enviarémoslle unha notificación por correo electrónico cando finalice o proceso.',
                failed: 'Produciuse un erro ao procesar a súa solicitude. Ténteo de novo máis tarde.',
            },
            model: {
                title: 'Eliminar conta',
                body: 'Ao eliminar a súa conta eliminaranse permanentemente todos os seus datos do noso sistema. Aínda quere continuar?',
                button: {
                    yes: 'Si',
                    no: 'Non',
                },
            },
        },
        limitExceedAddressError: 'Non se puido atopar o enderezo neste momento',
    },
    signup: {
        header: 'Cree a súa conta con',
        title: 'Rexístrese - ##COMPANY_BRAND_NAME##: Recarga rápida de vehículos eléctricos no Reino Unido',
        form: {
            firstName: 'Nome',
            lastName: 'Apelidos',
            email: 'Correo electrónico',
            password: 'Contrasinal',
            referralCode: 'Código de recomendación',
            code: 'Código',
            terms: 'Acepto',
            condition: 'Termos e condicións.',
        },
        button: {
            submit: 'Rexistrarse',
            signin: 'Iniciar sesión',
            login: 'Iniciar sesión',
            back: 'Volver',
            submitOtp: 'Enviar',
            resend: 'Volver enviar o código?',
        },
        label: {
            continueWith: 'Ou continúe con',
            accountNew: 'Xa ten unha conta?',
            otpConfirmationTitle1: 'Acabamos de enviarlle un correo electrónico',
            otpConfirmationTitle2: 'Para iniciar sesión, introduza o código que lle enviamos a',
            otpNonVerifiedEmailNote1: 'A súa conta aínda non está verificada. Introduza o código que lle enviamos a',
            otpNonVerifiedEmailNote2: 'para continuar.',
            resendTitle: 'Non recibiu o correo electrónico?',
            resend: 'Volver enviar',
            email: 'Correo electrónico',
            instavolt: '##COMPANY_BRAND_NAME##',
            modalMessage:
                '##EMAIL## xa rexistrou unha conta de ##COMPANY_BRAND_NAME## a través de ##FEDERATEDACCOUNT##. Quere continuar?',
            instavoltUserAccount: 'Conta de usuario de ##COMPANY_BRAND_NAME##',
            alreadyRegisteredWithNormalAcc:
                '##EMAIL## xa rexistrou unha conta de ##COMPANY_BRAND_NAME## a través da conta de usuario de ##COMPANY_BRAND_NAME##. Quere continuar?',
        },
        toast: {
            success: 'A conta de condutor creouse correctamente.',
            alreadyExist: 'O enderezo de correo electrónico que introduciu xa existe.',
            error: 'Produciuse un erro ao procesar a súa solicitude. Ténteo de novo máis tarde.',
            confirmationSuccess: 'A conta verificouse correctamente.',
            invalidCode: 'Vaites! Introduciu un código non válido.',
            resendSentOTP: 'O código OTP enviouse correctamente.',
            resendOTPErr: 'O código OTP non se enviou correctamente. Ténteo de novo máis tarde.',
            referralCodeInvalid: 'O código de recomendación non é válido.',
            resendSignupCode: 'Acabamos de enviarlle outro código. Comprobe o seu correo electrónico.',
            resendLimitExceeded: 'Acadou o máximo de intentos. Ténteo de novo pasado un tempo.',
        },
        errors: {
            recaptchaCode: 'Verificar código recaptcha.',
            passwordFormat:
                'O contrasinal debe ter máis de 10 caracteres, con polo menos unha letra maiúscula, unha letra minúscula, un carácter especial e un número.',
            securityCode: 'O código de seguranza debe ter polo menos 6 díxitos',
            firstName: 'O nome non é válido.',
            lastName: 'Os apelidos non son válidos.',
            termsAndCondition: 'Para poder continuar, debe ler e aceptar os Termos e condicións',
        },
    },
    Payment: {
        Title: 'Configuración de pagamento - ##COMPANY_BRAND_NAME##: Recarga rápida de vehículos eléctricos no Reino Unido',
        ChargeUnit: '(kWh)',
        description: 'Pagamento a ##COMPANY_BRAND_NAME##',
        PaymentType: {
            Title: 'Configuración de pagamento',
            SubTitle: 'Seleccione a opción de pagamento',
            Message: {
                selectPaymentOption: 'Seleccione a opción de pagamento.',
            },
            Inputs: {
                creditAccount: {
                    label: 'Conta de crédito',
                    label1: 'Pagar por uso',
                },
                paymentAccount: {
                    label: 'Conta de prepagamento',
                    label1: 'Pague por adiantado e utilice o crédito da conta para cargar',
                },
            },
            Buttons: {
                Next: {
                    text: 'Seguinte',
                },
            },
        },
        CreditPayment: {
            Title: 'Configuración de pagamento',
            SubTitle: 'Conta de crédito',
            note: 'Lembre que ao engadir unha tarxeta de pagamento, cobraranse ##CURRENCY## na súa conta, que se engadirán ao saldo do seu moedeiro de ##COMPANY_BRAND_NAME##.',
            donateMessage: {
                first: 'Si, doar',
                second: 'Arredondar o pagamento a',
            },
            donationNote:
                '«Arredondamento» de doazón aos 50 céntimos máis próximos ou recargar cunha cantidade fixa por cada operación de pagamento.',
            Inputs: {
                CardTitle: {
                    label: 'Nome que figura na tarxeta',
                    placeHolder: 'Nome que figura na tarxeta',
                    required: 'Requírese un título para a tarxeta',
                },
                CardNumber: {
                    label: 'Número de tarxeta',
                    placeHolder: '1234  1234  1234  1234',
                    invalid: 'Número de tarxeta non válido',
                    required: 'O número de tarxeta é obrigatorio',
                    maxlimit: 'O número de tarxeta debe ter como máximo 20 díxitos',
                },
                Expiry: {
                    label: 'Caducidade',
                    required: 'O mes de caducidade é obrigatorio',
                },
                Year: {
                    label: 'Ano',
                    required: 'O ano de caducidade é obrigatorio',
                },
                CSC: {
                    label: 'CSC',
                    label1: '(Código de seguranza da tarxeta)',
                    required: 'O código de seguranza da tarxeta é obrigatorio',
                    placeHolder: 'CSC',
                    maxlimit: 'Introduza un código de 3 díxitos como mínimo.',
                },
                Donation: {
                    label: 'Si, quero doar.',
                },
                giftaid: {
                    label: 'Habilitar a doazón con Gift Aid?',
                },
                RoundUp: {
                    label: 'Arredondar',
                },
                TopUp: {
                    label: 'Recargar',
                },
            },
            Buttons: {
                Save: {
                    text: 'Gardar',
                },
                Back: {
                    text: 'Cambiar a conta de prepagamento',
                },
                editBack: {
                    text: 'Volver',
                },
            },
            Card: {
                expire: 'Caduca',
                add: 'Engadir tarxeta',
                charity: 'Doazón benéfica',
                edit: 'Editar a configuración da doazón',
                editCard: 'Editar tarxeta',
                delete: 'Eliminar',
                replace: 'Substituír',
                balance: 'Saldo actual:',
                Outbalance: 'Saldo pendente:',
                mmYY: 'MM/AA',
                mm: 'MM',
                yyyy: 'AAAA',
                Pay: {
                    text: 'Pagar agora',
                },
                Withdraw: {
                    text: 'Retirar fondos',
                },
                toast: {
                    delete: {
                        success: 'A tarxeta eliminouse correctamente.',
                        error: 'Produciuse un erro ao procesar a súa solicitude. Ténteo de novo máis tarde.',
                    },
                    save: {
                        success: 'Os datos da tarxeta gardáronse correctamente.',
                        error: 'Produciuse un erro ao procesar a súa solicitude. Ténteo de novo máis tarde.',
                    },
                    paynow: {
                        success: 'Pagamento correcto.',
                        error: 'Produciuse un erro ao procesar a súa solicitude. Ténteo de novo máis tarde.',
                    },
                    edit: {
                        success: 'Os datos da tarxeta actualizáronse correctamente.',
                        error: 'Produciuse un erro ao procesar a súa solicitude. Ténteo de novo máis tarde.',
                    },
                    replace: {
                        success: 'Os datos da tarxeta substituíronse correctamente.',
                        error: 'Produciuse un erro ao procesar a súa solicitude. Ténteo de novo máis tarde.',
                    },
                    common: {
                        error: 'Produciuse un erro ao procesar a súa solicitude. Ténteo de novo máis tarde.',
                    },
                    amount: 'Non hai ningún saldo pendente de cobro.',
                    noSwitch: 'Liquide o seu saldo pendente antes de cambiar a unha conta de prepagamento',
                },
            },
            label: {
                accountFunds: 'Fondos da conta',
                rewards: 'Recompensas',
                referralBonus: 'Bonificación por recomendación',
                currentBalance: 'Saldo actual',
                seeMore: 'Ver máis',
                message: 'Mensaxe',
                charityDonation: 'Doazón benéfica',
                topupAmount: 'Si, doar',
                note: 'Nota:',
                charity: 'organización benéfica',
                card: 'tarxeta',
                donation: 'doazón',
                giftaid: 'giftaid',
                noThanks: 'Non, grazas',
                cancel: 'Cancelar',
            },
            error: 'A súa transacción de pagamento está por debaixo do valor mínimo de transacción ##AMOUNT',
        },
        PrePaymentDetail: {
            Title: 'Configuración de pagamento',
            SubTitle: 'Conta de prepagamento',
            Inputs: {
                Amount: {
                    label: 'Importe',
                    placeHolder: 'Importe',
                    required: 'O importe é obrigatorio',
                    invalid: 'O importe debe ser igual ou superior a',
                    maxLimit: '',
                    number: 'O importe debe ser un número',
                },
                CardNumber: {
                    label: 'Número de tarxeta',
                    placeHolder: '1234  1234  1234  1234',
                    required: 'O número de tarxeta é obrigatorio',
                    invalid: 'Número de tarxeta non válido',
                },
                Expiry: {
                    label: 'Caducidade',
                    required: 'O mes de caducidade é obrigatorio',
                },
                Year: {
                    label: 'Ano',
                    required: 'O ano de caducidade é obrigatorio',
                },
                CSC: {
                    label: 'CSC',
                    label1: '(Código de seguranza da tarxeta)',
                    required: 'O código de seguranza da tarxeta é obrigatorio',
                    placeHolder: 'CSC',
                    maxlimit: 'Introduza un código de 3 díxitos como mínimo.',
                },
                Donation: {
                    label: 'Si, quero doar.',
                },
                RoundUp: {
                    label: 'Arredondar',
                },
                TopUp: {
                    label: 'Recargar',
                },
            },
            Buttons: {
                Save: {
                    text: 'Engadir diñeiro',
                },
                Back: {
                    text: 'Cambiar a conta de crédito',
                    text1: 'Volver',
                },
                FailBack: {
                    text: 'Volver á conta',
                },
            },
            PrePayemnt: {
                text: {
                    currentBalance: 'Saldo actual',
                    OutBalance: 'Saldo pendente',
                },
                button: {
                    add: 'Engadir diñeiro',
                    Withdraw: 'Retirar fondos',
                    withdrawAccountFunds: 'Retirar fondos da conta',
                    viewYourRefundRequests: 'Ver solicitudes de retirada',
                },
            },
            toast: {
                canNotAddMoney:
                    'Non pode engadir máis fondos porque acadou o límite máximo de fondos que pode ter no seu moedeiro de ##COMPANY_BRAND_NAME##.',
                common: {
                    error: 'Produciuse un erro ao procesar a súa solicitude. Ténteo de novo máis tarde.',
                },
                maxLimitForPrePayment: 'Só pode cargar o saldo do seu moedeiro ata ##MAXAMOUNT.',
                maxLimitReached:
                    'Só pode cargar o saldo do seu moedeiro ata ##MAXAMOUNT. Agora pode engadir un máximo de ##ALLOWEDAMOUNT.',
            },
            label: {
                accountFunds: 'Fondos da conta',
                rewards: 'Recompensas',
                referralBonus: 'Bonificación por recomendación',
                currentBalance: 'Saldo actual',
                seeMore: 'Ver máis',
                billingAddress: 'Enderezo de facturación',
                charityDonation: 'Doazón benéfica',
                note: 'Nota:',
            },
            donationNote: ' Recarga da doazón cunha cantidade fixa por cada transacción de pagamento.',
        },
        PrePaymentWithdraw: {
            Title: 'Configuración de pagamento',
            SubTitle: 'Conta de prepagamento',
            Inputs: {
                Amount: {
                    label: 'Importe',
                    placeHolder: 'Importe',
                    required: 'O importe é obrigatorio',
                    invalid: 'O importe é superior ao saldo actual',
                },
                CardNumber: {
                    label: 'Número de tarxeta',
                    placeHolder: '1234  1234  1234  1234',
                    required: 'O número de tarxeta é obrigatorio',
                    invalid: 'Número de tarxeta non válido',
                    maxlimit: 'O número de tarxeta debe ter como máximo 20 díxitos',
                },
                Expiry: {
                    label: 'Caducidade',
                    required: 'O mes de caducidade é obrigatorio',
                },
                Year: {
                    label: 'Ano',
                    required: 'O ano de caducidade é obrigatorio',
                },
                CSC: {
                    label: 'CSC',
                    label1: '(Código de seguranza da tarxeta)',
                    required: 'O código de seguranza da tarxeta é obrigatorio',
                    placeHolder: 'CSC',
                    maxlimit: 'Introduza un código de 3 díxitos polo menos.',
                },
            },
            Buttons: {
                Withdraw: {
                    text: 'Retirar fondos',
                },
                Back: {
                    text: 'Cambiar a conta de crédito',
                    text1: 'Volver',
                },
                FailBack: {
                    text: 'Volver á conta',
                },
            },
            PrePayemnt: {
                text: {
                    currentBalance: 'Saldo actual',
                    OutBalance: 'Saldo pendente',
                },
                button: {
                    add: 'Engadir diñeiro',
                    Withdraw: 'Retirar fondos',
                },
            },
        },
        PaymentSuccess: {
            label: 'Correcto!',
            Successlabel: 'Transacción correcta',
            transactionId: 'Id de transacción:',
            amount: 'Importe:',
            warningLabel: 'Aviso',
            common: {
                error: 'Produciuse un erro ao procesar a súa solicitude. Ténteo de novo máis tarde.',
            },
        },
        PaymentFail: {
            label: 'Erro na transacción',
            failureLabel: 'Produciuse un erro na súa transacción. Ténteo de novo.',
        },
        PaymentProcess: {
            label: 'Agarde mentres se procesa a súa transacción',
        },
        Model: {
            header: 'Cambiar de conta',
            DeleteBoxHeader: 'Eliminar tarxeta',
            WithdrawBoxHeader: 'Retirar fondos',
            button: {
                yes: 'Si',
                no: 'Non',
                existingCard: 'Tarxeta existente',
                newCard: 'Nova tarxeta',
            },
            messagePrePayment: 'Seguro que quere cambiar a conta de prepagamento?',
            messageCreditPayment: 'Seguro que quere cambiar a conta de crédito?',
            msgPrePaymentSaveCard:
                'Ao cambiar a conta de prepagamento, eliminaranse os datos da súa tarxeta. Seguro que quere cambiar a conta de prepagamento?',
            DeleteCardMsg: 'Seguro que quere eliminar os datos da tarxeta?',
            WithdrawMoneyMsg: 'Seleccione a opción de tarxeta para retirar diñeiro.',
        },
        charityModal: {
            yesDonate: 'Si, doar',
        },
        Withdraw: {
            CanNotWithdrawMoneyWhileSessionIsActive:
                'Non podes retirar cartos neste momento. Agarda a que se complete a sesión de carga en execución',
        },
    },
    termsAndCondition: {
        title: 'Termos e condicións',
        note: 'Páxina en construción.',
    },
    table: {
        data: {
            noData: 'Non se atoparon datos.',
        },
        pagination: {
            prev: 'Anterior',
            nrev: 'Seguinte',
            showing: 'Mostrando',
            pageSize: {
                ten: '10',
                fifty: '50',
                hundred: '100',
            },
        },
    },
    history: {
        headerTitle:
            'Historial de carga e pagamento - ##COMPANY_BRAND_NAME##: Recarga rápida de vehículos eléctricos no Reino Unido',
        title: 'Historial de carga e pagamento',
        columns: {
            chargingStation: 'Estación de carga',
            date: 'Data',
            address: 'Enderezo',
            milesAdded: 'Quilómetros engadidos',
            time: 'Hora',
            cost: 'Custo',
            energy: 'Enerxía',
            action: 'Acción',
            remark: 'Observacións',
            credit: 'Crédito',
            debit: 'Débito',
            closing: 'Pechando',
            transaction: 'ID de transacción',
            credit_debit: 'Crédito/débito',
        },
        form: {
            charge: 'Cargando',
            payment: 'Pagamento',
            month: 'Mes',
        },
        label: {
            exportAll: 'Xerar recibo',
        },
        button: {
            search: 'Buscar',
            reset: 'Restablecer',
        },
        message: {
            selectRecordToDownload: 'Seleccione calquera rexistro para xerar un recibo.',
            noChargingHistoryMessage: 'Cando cargue o seu VE, o seu historial de carga mostrarase aquí.',
            noPaymentHistoryMessage: 'Cando complete unha transacción de pagamento, mostrarase aquí.',
        },
        receipt: {
            title: 'RECIBO',
            header: {
                date: 'Data',
                billing_addresss: 'Enderezo de facturación',
                note: 'O seu recibo de carga de VE de ##COMPANY_BRAND_NAME##',
                vat_number: 'Número de IVE',
            },
            content: {
                summary: 'A seguir móstrase un resumo das súas transaccións de carga',
                table: {
                    header: {
                        station_name: 'Nome da estación',
                        start: 'Inicio',
                        end: 'Fin',
                        usage: 'Uso',
                        unit: 'Unidade',
                        value: 'Valor',
                        transaction_id: 'ID de transacción',
                        vatRate: 'Taxa de IVE',
                        vat: 'IVE',
                    },
                },
                total_before_vat: 'Total antes do IVE',
                total_vat: 'Total IVE',
                vat: 'IVE a',
                total_fee: 'Taxa total',
                thanks_message: 'Grazas por realizar a súa carga na rede de carga de VE de ##COMPANY_BRAND_NAME##',
                vatReceiptNotGenerated:
                    'Aínda non se xerou o recibo do IVE. Ténteo de novo pasadas 24 horas da finalización da sesión de carga. Se segue sen conseguilo, póñase en contacto co noso servizo de atención ao cliente.',
                vatReceiptNotAssociatedWithAcc:
                    'O recibo de IVE non se asociou á súa conta. Asegúrese de que está a usar as credenciais correctas para ver o recibo do IVE.',
            },
            footer: {
                title: '##COMPANY_BRAND_NAME## Limited é unha empresa rexistrada en Inglaterra e Gales, co número 10484882',
                address: '6 Cedarwood, Crockford Lane, Chineham Park, Basingstoke, Hampshire, RG24 8WD',
                telephone: 'Tel.:',
                email: 'E-mail:',
                note: 'Indique a data e o ID da transacción en toda a correspondencia.',
            },
        },
    },
    loyaltyPoint: {
        header: 'Recompensas - ##COMPANY_BRAND_NAME##. Recarga rápida de vehículos eléctricos no Reino Unido',
        title: 'Recompensas',
        note: '*Só pode trocar os voltios a través da nosa aplicación.',
        availableVolts: 'Voltios dispoñibles:',
        noDataMessage: 'Tras completar unha sesión de carga, as súas transaccións de voltios mostraranse aquí.',
        label: {
            chargingStation: 'Estación de carga',
            date: 'Data',
            address: 'Enderezo',
            credit: 'Crédito',
            debit: 'Débito',
            closing: 'Pechando',
            redeemed: 'Trocado',
            charged: 'Cargado',
            remark: 'Observacións',
            credit_debit: 'Crédito/débito',
            voltsRedeemed: 'Voltios trocados',
        },
    },
    withdraw: {
        subTitle: 'Conta de prepagamento',
        label: {
            maxWithdrawalBalance: 'Saldo máximo de retirada',
            note: 'Nota:',
            enterAmount: 'Introduza un importe',
            withdrawAmount: 'Importe de retirada de fondos',
            expires: 'Caduca',
            rewardHeading: 'Introduza o valor que desexa retirar da súa conta',
            selectCardToWithdrawAmount: 'Seleccione a tarxeta para retirar o importe',
            remainingAmount: 'Importe restante',
            accountName: 'Nome da conta',
            sortCode: 'Sort Code',
            accountNumber: 'Número de conta',
            bankDetail: 'Datos bancarios',
            withdrawAmountNote:
                'Só poderá transferir os fondos da súa conta á tarxeta de pagamento que se usou para ingresar fondos na conta.',
            withdrawAmountNote1: 'Teña en conta que a retirada se procesará nun prazo de 14 días.',
            withdrawAmountNote2: 'Teña en conta que a retirada se procesará nun prazo de 3 a 5 días laborables.',
            accountNameNote:
                ' Asegúrese de que o nome da conta bancaria coincide co nome da súa conta de ##COMPANY_BRAND_NAME##. De non ser así, rexeitarase a retirada de fondos.',
        },
        placeholder: {
            amount: 'Importe',
            accountName: 'Nome da conta',
            sortCode: 'Sort Code',
            accountNumber: 'Número de conta',
        },
        button: {
            back: 'Volver',
            next: 'Seguinte',
            raisedRequest: 'Presentar solicitude de retirada de fondos',
            withdrawMoney: 'Retirar fondos',
            proceed: 'Continuar',
        },
        errors: {
            amount: {
                typeError: 'Introduza un importe válido',
                moreThan: 'O importe debe ser superior a 0',
            },
            AmountIsGreaterThanwithdrawableAmount: 'O importe introducido é superior ao importe que se pode retirar',
            selectCardToWithdrawMoney: 'Seleccione calquera tarxeta para retirar diñeiro.',
            canNotSelectCard:
                'O valor da retirada de fondos supera o máximo de retirada dispoñible para a tarxeta de pagamento seleccionada. Elixa outra tarxeta.',
        },
    },
    termsAndConditionListing: {
        title: 'Termos e condicións - ##COMPANY_BRAND_NAME##: Recarga rápida de vehículos eléctricos no Reino Unido',
        noteDetails: '*Os campos obrigatorios teñen que aceptarse para poder usar ou rexistrar unha conta.',
        label: {
            referralCode: 'Código de recomendación (se o ten)',
            promotioncode: 'Código promocional (se o ten)',
            termsAndConditions: 'Termos e condicións.',
            welcome: 'Dámoslle a benvida',
            apply: 'Aplicar',
            note: 'Nota:',
            invalidCode: 'Vaites! Código non válido',
            usedCode: 'Código xa en uso',
            codeApplied: 'Código aplicado',
            iAggreeToTermsCondition: 'Acepto',
            reviewUpdatedCondition:
                'Revise e acepte os termos e condicións actualizados que se enumeran a continuación.',
            name: 'Nome',
            accepted: 'Aceptado',
        },
        button: {
            next: 'Seguinte',
            cancel: 'Cancelar',
            accept: 'Aceptar',
        },
        errors: {
            acceptTermCondition: 'Acepte os * Termos e condicións obrigatorios para poder continuar.',
            invalidReferralCode:
                'O código de referencia ##REFERRAL_CODE## introducido non é válido. Para continuar, corrixa (o valor do texto distingue entre maiúsculas e minúsculas) ou elimine o Código de recomendación.',
            invalidPromotionCode:
                'O código promocional ##PROMO_CODE## introducido non é válido. Para continuar, corrixa (o valor do texto distingue entre maiúsculas e minúsculas) ou elimine o Código promocional.',
            referralNotAllowed: 'Os códigos de recomendación non se poden usar para esta promoción',
        },
        message: {
            refferalCode: {
                first: 'O código de recomendación',
                second: 'introducido non é válido. Para continuar, corrixa (o valor do texto distingue entre maiúsculas e minúsculas) ou elimine o Código de recomendación.',
            },
            promotionCode: {
                first: 'O código promocional',
                second: 'introducido non é válido. Para continuar, corrixa (o valor do texto distingue entre maiúsculas e minúsculas) ou elimine o Código promocional.',
            },
        },
    },
    cookiePolicy: {
        text: 'Este sitio web utiliza cookies para mellorar a experiencia do usuario. Ao usar o noso sitio web, vostede confirma que leu e comprendeu a nosa',
        action: 'Política de cookies',
        iAccept: 'Acepto',
    },
    openSource: {
        title: 'Créditos',
    },
    refundRequest: {
        title: 'Solicitudes de retirada - ##COMPANY_BRAND_NAME##: Recarga rápida de vehículos eléctricos no Reino Unido',
        header: 'Solicitudes de retirada de fondos',
        label: {
            name: 'Nome',
            requestedDate: 'Data solicitada',
            refundRequested: 'Retirada de fondos solicitada',
            account: 'Conta (para retirar crédito)',
            remarks: 'Observacións',
            transactionID: 'ID da solicitude',
            processing: 'Procesando',
            cancelled: 'Cancelada',
            declined: 'Rexeitada',
            complete: 'Completar',
            date: 'Data',
            status: 'Estado',
            cancelRequest: 'Cancelar solicitude',
            action: 'Acción',
        },
        button: {
            search: 'Buscar',
            reset: 'Restablecer',
            yes: 'Si',
            no: 'Non',
        },
        placeholder: {
            selectStatus: 'Seleccionar estado',
        },
        message: {
            confirmCancelRequest: 'Seguro que quere cancelar a solicitude de retirada de fondos?',
        },
        errors: {
            sortCode: {
                min: 'Introduza un Sort Code de 6 díxitos como mínimo.',
                valid: 'Introduza un Sort Code válido.',
            },
            accountNumber: {
                min: 'Introduza un número de conta de 8 díxitos como mínimo.',
            },
        },
    },
    footer: {
        version: 'Versión',
        copyRightText: 'Todos os dereitos reservados',
    },
    rfidCards: {
        headerTitle: 'Tarxetas RFID - ##COMPANY_BRAND_NAME##. Recarga rápida de vehículos eléctricos no Reino Unido',
        title: 'Tarxetas RFID',
        label: {
            public_card_id: 'Número(s) de tarxeta',
            quantity: 'Introduza unha cantidade',
            action: 'Acción',
            delete: 'Eliminar',
            cardNumber: 'Introduza un número de tarxeta',
            rfid_card_unit_price: 'O prezo unitario dunha tarxeta RFID é',
            rfid_card_unit_price_label: 'Por tarxeta RFID',
        },
        placeholder: {
            quantity: 'Introduza unha cantidade',
            public_card_id: 'Introduza un número de tarxeta',
        },
        noDataMessage: 'Non hai ningunha tarxeta RFID activa neste momento',
        requestProcessDaysMessage:
            'A súa solicitude de tarxeta RFID procesarase nun prazo de ##DAYS## días laborables.',
        modal: {
            header: 'Solicitude de tarxeta RFID',
            body: 'Introduza o número de tarxetas RFID necesarias. As tarxetas enviaranse ao enderezo que configurou na súa conta. Cando reciba as tarxetas, deberá activalas na súa conta.',
        },
        addCardModal: {
            header: 'Engadir tarxeta RFID',
        },
        button: {
            proceed: 'Continuar',
            cancel: 'Cancelar',
            addNewCard: 'Engadir tarxeta RFID',
            requestCard: 'Solicitar nova tarxeta RFID',
            delete: 'Eliminar',
            action: 'Acción',
        },
        errors: {
            quantity: {
                min: 'A cantidade debe ser superior a cero',
            },
            public_card_id: {
                valid: 'Non se recoñeceu o ID de tarxeta introducido. Asegúrese de que non se incluíron espazos nin caracteres especiais no ID.',
            },
        },
        message: {
            here: 'aquí',
            deleteConfirmMessage:
                'Ao eliminar esta tarxeta, quedará desactivada permanentemente e non poderá volver rexistrarse. Seguro que quere continuar?',
        },
    },
    giftAidmessage: 'mensaxe de gift aid',
    creditAccountDonationNote:
        'Ao seleccionar Si, aplicarase unha doazón de ##AMOUNT## a todos os pagamentos futuros. Pode cambiar a súa preferencia de doazón en calquera momento facendo clic no botón «Editar configuración da doazón».',
    PrepaymentAccountDonationNote:
        'Ao seleccionar Si, aplicarase unha doazón de ##AMOUNT## a esta transacción de pagamento. Pode cambiar a súa preferencia de doazón en calquera momento facendo clic no botón «Editar configuración da doazón».',
    aboutUs: {
        listTitle: {
            openSourcelicenses: 'Licenzas de código aberto',
        },
    },
    price_breakdown_details: {
        detailed_breakup: 'Desagregación detallada',
        total_cost: 'Custo total',
        conversion_rate: 'Taxa de conversión',
    },
};
