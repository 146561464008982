import React from 'react';
import { Dropdown, Navbar } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAppHeaderProps } from '../types/PropTypes';
import { AppState } from '../store/state/app.state';
import { connect } from 'react-redux';
import { IHeaderState } from '../types/stateTypes';
import images from '../assets/images';
import LoginService from '../services/login/login-service';

class Header extends React.Component<IAppHeaderProps & WithTranslation, IHeaderState> {
    loginService: LoginService;
    constructor(props) {
        super(props);

        this.state = {
            showNavBar: false,
            companyName: '',
        };
        this.loginService = new LoginService();
    }

    getUserName = (): string => {
        const { userData } = this.props;
        if (userData && userData.firstName && userData.lastName) {
            if (userData.firstName.toString().trim() === '' && userData.lastName.toString().trim() === '') {
                return `${userData.email}`;
            }
            return `${userData.firstName} ${userData.lastName}`;
        }
        return '';
    };
    toggleNavBar = () => {
        this.setState({
            showNavBar: !this.state.showNavBar,
        });
    };

    getNavLinkClass = (path) => {
        return window.location.pathname === path ? 'nav-item active' : 'nav-item';
    };
    async componentWillMount() {
        const config = await this.loginService.getAPIKey({ code: 'COMPANY_NAME' });
        this.setState({
            companyName: config.data[0]?.value,
        });
    }

    render() {
        const { t } = this.props;
        return (
            <header className="header">
                <div className="container-xl">
                    <div className="row no-gutters align-items-center">
                        <div className="col">
                            <Navbar expand="lg" onToggle={this.toggleNavBar} expanded={this.state.showNavBar}>
                                <Navbar.Brand as={Link} to="/" title={this.state.companyName}>
                                    <img src={images.logoPNG} alt={t('navbar.title')} />
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav">
                                    <span className="one"></span>
                                    <span className="two"></span>
                                    <span className="three"></span>
                                </Navbar.Toggle>
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <ul className="navbar-nav m-auto" id="navbar-nav">
                                        <li className={this.getNavLinkClass('/')} id="/" onClick={this.toggleNavBar}>
                                            <NavLink
                                                to="/"
                                                className="nav-link"
                                                title={t('navbar.menu.chargingPaymentHistory')}
                                            >
                                                {t('navbar.menu.chargingPaymentHistory')}
                                            </NavLink>
                                        </li>
                                        <li
                                            className={this.getNavLinkClass('/payment')}
                                            id="/payment"
                                            onClick={this.toggleNavBar}
                                        >
                                            <NavLink
                                                to="/payment"
                                                className="nav-link"
                                                title={t('navbar.menu.paymentConfiguration')}
                                            >
                                                {t('navbar.menu.paymentConfiguration')}
                                            </NavLink>
                                        </li>
                                        <li
                                            className={this.getNavLinkClass('/rewards')}
                                            id="/rewards"
                                            onClick={this.toggleNavBar}
                                        >
                                            <NavLink
                                                to="/rewards"
                                                className="nav-link"
                                                title={t('navbar.menu.rewards')}
                                                activeClassName="active"
                                            >
                                                {t('navbar.menu.rewards')}
                                            </NavLink>
                                        </li>
                                        <li
                                            className={this.getNavLinkClass('/rfid-cards')}
                                            id="/rfid-cards"
                                            onClick={this.toggleNavBar}
                                        >
                                            <NavLink
                                                to="/rfid-cards"
                                                className="nav-link"
                                                title={t('navbar.menu.rfidCards')}
                                                activeClassName="active"
                                            >
                                                {t('navbar.menu.rfidCards')}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Navbar.Collapse>
                                <div
                                    className={this.state.showNavBar ? 'menu-overlay' : 'menu-overlay collapsed'}
                                    onClick={this.toggleNavBar}
                                ></div>
                            </Navbar>
                        </div>
                        <div className="col-auto">
                            <div className="header-right-bar">
                                <Dropdown className="user-block">
                                    <Dropdown.Toggle as="a" id="dropdownMenuLink" href="#" role="button">
                                        <img className="user-icon" src={images.avatarSVG} alt="" />
                                        <span
                                            className="name text-truncate capitalize avatarIcon"
                                            title={this.getUserName()}
                                        >
                                            {this.getUserName()}
                                        </span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to="/Profile">
                                            <img src={images.profileSVG} alt={t('navbar.myProfile')} />
                                            {t('navbar.myProfile')}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            as={Link}
                                            to="/terms-and-conditions"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                src={images.icTermsSVG}
                                                alt={t('navbar.termsConditions')}
                                                className="menuItemIcon"
                                            />
                                            {t('navbar.termsConditions')}
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={this.props.logout}>
                                            <img src={images.logoutSVG} alt={t('navbar.logOut')} />
                                            {t('navbar.logOut')}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    userData: state.UserData,
});
export default connect(mapStateToProps, null)(withTranslation()(Header));
