export default {
    route: {
        dashboard: 'Panel de control',
        article: 'Artículos',
        stationTitle: 'Detalles de la estación: ##COMPANY_BRAND_NAME## - Carga rápida de VE Reino Unido',
        or: 'O',
    },
    month: {
        jan: 'Ene',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Abr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Ago',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dic',
    },
    error: {
        accessDenied: 'Acceso denegado',
        somethingWrong: '¡Algo ha fallado!',
        notFound: 'Página no encontrada',
    },
    navbar: {
        title: '##COMPANY_BRAND_NAME##',
        logOut: 'Cerrar sesión',
        myProfile: 'Mi perfil',
        termsConditions: 'Términos y condiciones',
        menu: {
            dashboard: 'Panel de control',
            paymentConfiguration: 'Configuración de pago',
            chargingPaymentHistory: 'Historial de cargas y pagos',
            rewards: 'Recompensas',
            rfidCards: 'Tarjetas RFID',
        },
    },
    login: {
        title: 'Iniciar sesión - ##COMPANY_BRAND_NAME##: Carga rápida de VE Reino Unido',
        header: 'Bienvenido/a a',
        form: {
            email: 'ID de correo electrónico',
            password: 'Contraseña',
        },
        placeholder: {
            region: 'Seleccionar región',
            language: 'Seleccionar idioma',
        },
        button: {
            submit: 'Iniciar sesión',
            forgotPassword: '¿Ha olvidado la contraseña?',
            signup: 'Crear una cuenta',
            signin: 'Iniciar sesión',
        },
        label: {
            logo: '##COMPANY_BRAND_NAME##',
            thirdPartyLogin: 'O inicie sesión en',
            rememberMe: 'Recordarme',
            newAccount: '¿No tiene una cuenta?',
            facebook: 'Iniciar sesión con Facebook',
            google: 'Iniciar sesión con Google',
            apple: 'Iniciar sesión con Apple',
            copyright: 'Derechos de autor ##COMPANY_BRAND_NAME## Ltd',
        },
        toast: {
            success: 'Inicio de sesión de conductor satisfactorio.',
            inValid: 'Introduzca el correo electrónico y la contraseña correctos.',
            error: 'Se ha producido un error al procesar su solicitud, vuelva a intentarlo más tarde.',
            cancelled: 'La cancelación de su cuenta está procesándose, de modo que no podrá realizar ninguna acción.',
            maximumAttempt: 'Ha alcanzado el número máximo de intentos. Vuelva a intentarlo más tarde.',
            reminderMessage:
                'Añada una tarjeta de pago en su cuenta de crédito o añada dinero a su cuenta de prepago para activar el pago asociado a este código de promoción.',
        },
        errors: {
            inValidEmail: 'La dirección de correo electrónico no es válida.',
        },
    },
    forgot: {
        title: 'He olvidado la contraseña - ##COMPANY_BRAND_NAME##: Carga rápida de VE Reino Unido',
        resetTitle: 'Restablezca la contraseña - ##COMPANY_BRAND_NAME##: Carga rápida de VE Reino Unido',
        header: '¿Ha olvidado la contraseña?',
        subHeader:
            'Introduzca a continuación su correo electrónico y le enviaremos un mensaje para restablecer la contraseña',
        resetHeader: 'Restablezca la contraseña.',
        resetSubHeader: 'Introduzca el código que le hemos enviado por correo electrónico y restablezca la contraseña.',
        supportNumber: 'Nuestro número de soporte',
        button: {
            back: 'Atrás',
            submit: 'Restablecer mi contraseña',
            save: 'Guardar contraseña nueva',
        },
        form: {
            email: 'Correo electrónico',
            code: 'Código',
            password: 'Contraseña',
            confirmPassword: 'Vuelva a confirmar la contraseña',
            message:
                'Si tiene una cuenta de correo válida, recibirá un código a su correo electrónico. Tenga en cuenta que si ha creado una cuenta de ##COMPANY_BRAND_NAME## con una cuenta federada, por ejemplo, de Apple, Google o Facebook, no podrá restablecer la contraseña porque está gestionada por el servicio de terceros. Si necesita más asistencia, puede contactar con nosotros',
        },
        toast: {
            success: 'Su contraseña se ha cambiado con éxito.',
            error: 'Se ha producido un error al procesar su solicitud, vuelva a intentarlo más tarde.',
            inValidEmailAddress: 'La dirección de correo electrónico no es válida.',
            invalidCode: '¡Vaya! El código no es válido.',
        },
        errors: {
            passwordFormat:
                'La contraseña debe incluir al menos 10 caracteres, y al menos una mayúscula, una minúscula, un carácter especial y un número.',
            confirmPasswordMatch:
                'la contraseña de confirmación debe coincidir con la nueva contraseña que ha introducido.',
            verificationCodeFormat: 'Introduzca el código de verificación de 6 dígitos.',
        },
    },
    dashboard: {
        title: 'Panel de control - ##COMPANY_BRAND_NAME##: Carga rápida de VE Reino Unido',
        header: 'Panel de control',
        premiumSubscription:
            '##COMPANY_BRAND_NAME## presta un servicio premium. Seleccione suscribirse para disfrutar gratis de las funciones del servicio premium durante 3 meses.',
    },
    permission: {
        createArticle: 'Añadir artículo',
        editArticle: 'Editar artículo',
        save: 'Guardar',
        confirm: '¿Está seguro/a?',
        cancel: 'Cancelar',
        update: 'Actualizar',
    },
    profile: {
        title: 'Perfil - ##COMPANY_BRAND_NAME##: Carga rápida de VE Reino Unido',
        header: 'Perfil',
        addresses: 'Direcciones',
        personalDetails: {
            header: 'Datos personales',
            label: {
                firstName: 'Nombre',
                lastName: 'Apellidos',
                email: 'ID de correo electrónico',
                address: 'Dirección',
                billingAddress: 'Dirección de facturación',
                postcode: 'Código postal',
                samePersonalAddress: 'Igual que la dirección personal',
            },
            placeholder: {
                firstName: 'Nombre',
                lastName: 'Apellidos',
                email: 'ID de correo electrónico',
                address: 'Introduzca su dirección',
                billingAddress: 'Dirección de facturación',
                postcode: 'Código postal',
                searchAddress: 'Empiece a escribir su dirección para buscar',
            },
            button: {
                save: 'Guardar',
                cancel: 'Cancelar',
                yes: 'Sí',
                no: 'No',
            },
            errors: {
                email: {
                    email: 'La dirección de correo electrónico no es válida.',
                },
                first_name: {
                    matches: 'El nombre no es válido.',
                },
                last_name: {
                    matches: 'El apellido no es válido.',
                },
                address: {
                    address: 'Debe tener al menos ##MIN_LENGTH## caracteres',
                    invalid: 'La dirección no es válida',
                },
                billing_Address: {
                    billing_Address: 'Debe tener al menos ##MIN_LENGTH## caracteres',
                    invalid: 'La dirección de facturación no es válida',
                },
            },
            model: {
                header: 'Encontrar dirección',
                address: 'Escribir dirección',
                suggested_address: 'Direcciones sugeridas',
                no_data_found: 'No se han encontrado direcciones',
                no_address_found: 'No se ha encontrado ninguna dirección.',
                button: {
                    ok: 'De acuerdo',
                    cancel: 'Cancelar',
                    close: 'Cerrar',
                },
                toast: {
                    invalidAddress: 'Se ha introducido un código postal o una dirección no válida.',
                },
            },
        },
        addressDetails: {
            header: 'Dirección',
            label: {
                address1: 'Dirección',
                address2: 'Línea de dirección 2',
                city: 'Ciudad',
                county: 'Condado',
                paymentPostcode: 'Código postal',
            },
            placeholder: {
                address1: 'Dirección',
                address2: 'Línea de dirección 2',
                city: 'Ciudad',
                county: 'Condado',
            },
        },
        billingAddressDetails: {
            header: 'Dirección de facturación',
            label: {
                billingaddress1: 'Dirección',
                billingaddress2: 'Línea de dirección 2',
                billingcity: 'Ciudad',
                billingcounty: 'Condado',
                samePersonalAddress: 'Igual que la dirección personal',
            },
            placeholder: {
                billingaddress1: 'Dirección',
                billingaddress2: 'Línea de dirección 2',
                billingcity: 'Ciudad',
                billingcounty: 'Condado',
            },
        },
        marketingPreferenceDetails: {
            header: 'Preferencias de marketing',
            label: {
                receivePreference: 'Recibir comunicaciones de marketing de ##COMPANY_BRAND_NAME##',
                yes: 'Sí',
                no: 'No',
            },
            message: '¿Le gustaría recibir comunicaciones de marketing de ##COMPANY_BRAND_NAME##?',
        },
        langPreference: 'Preferencia de idioma',
        changePassword: {
            header: 'Contraseña',
            toast: {
                success: 'Su contraseña se ha cambiado satisfactoriamente.',
                error: '¡Vaya! Su contraseña actual es incorrecta.',
                commonError: 'Se ha producido un error al procesar su solicitud, vuelva a intentarlo más tarde.',
            },
            button: {
                update: 'Actualizar',
            },
            label: {
                current_password: 'Contraseña actual',
                new_password: 'Contraseña nueva',
                confirm_password: 'Vuelva a confirmar la contraseña',
            },
            placeholder: {
                current_password: 'Contraseña actual',
                new_password: 'Contraseña nueva',
                confirm_password: 'Vuelva a confirmar la contraseña',
            },
            errors: {
                passwordFormat:
                    'La contraseña debe incluir al menos 10 caracteres, y al menos una mayúscula, una minúscula, un carácter especial y un número.',
                confirmPasswordMatch:
                    'La contraseña de confirmación debe coincidir con la nueva contraseña que ha introducido.',
            },
        },
        cancelAccount: {
            header: 'Eliminar cuenta',
            note1: 'Puede eliminar su cuenta en cualquier momento. ##COMPANY_BRAND_NAME## eliminará todos sus datos de nuestro sistema.',
            note2: 'Para eliminar su cuenta.',
            clickHere: 'Haga clic aquí',
            toast: {
                success:
                    'Lamentamos que se vaya. Hemos iniciado el proceso de eliminación de su cuenta y se lo notificaremos por correo electrónico cuando el proceso se haya completado.',
                failed: 'Se ha producido un error al procesar su solicitud, vuelva a intentarlo más tarde.',
            },
            model: {
                title: 'Eliminar cuenta',
                body: 'Al eliminar su cuenta se borrarán todos sus datos de nuestro sistema. ¿Aún desea continuar?',
                button: {
                    yes: 'Sí',
                    no: 'No',
                },
            },
        },
        limitExceedAddressError: 'No hemos podido encontrar la dirección en este momento',
    },
    signup: {
        header: 'Cree su cuenta con',
        title: 'Crear cuenta - ##COMPANY_BRAND_NAME##: Carga rápida de VE Reino Unido',
        form: {
            firstName: 'Nombre',
            lastName: 'Apellidos',
            email: 'Correo electrónico',
            password: 'Contraseña',
            referralCode: 'Código de recomendación',
            code: 'Código',
            terms: 'Acepto los',
            condition: 'Términos y condiciones.',
        },
        button: {
            submit: 'Crear una cuenta',
            signin: 'Iniciar sesión',
            login: 'Iniciar sesión',
            back: 'Atrás',
            submitOtp: 'Enviar',
            resend: '¿Reenviar código?',
        },
        label: {
            continueWith: 'O continuar con',
            accountNew: '¿Ya tiene una cuenta?',
            otpConfirmationTitle1: 'Acabamos de enviarle un correo electrónico',
            otpConfirmationTitle2: 'Para iniciar sesión, introduzca el código que hemos enviado a',
            otpNonVerifiedEmailNote1: 'Su cuenta aún no se ha verificado. Introduzca el código que hemos enviado a',
            otpNonVerifiedEmailNote2: 'para continuar.',
            resendTitle: '¿No ha recibido el correo electrónico?',
            resend: 'Volver a enviar',
            email: 'Correo electrónico',
            instavolt: '##COMPANY_BRAND_NAME##',
            modalMessage:
                '##EMAIL## ya ha registrado una cuenta de ##COMPANY_BRAND_NAME## a través de ##FEDERATEDACCOUNT##. ¿Desea continuar?',
            instavoltUserAccount: 'Cuenta de usuario de ##COMPANY_BRAND_NAME##',
            alreadyRegisteredWithNormalAcc:
                '##EMAIL## ya ha registrado una cuenta de ##COMPANY_BRAND_NAME## a través de una cuenta de usuario de ##COMPANY_BRAND_NAME##. ¿Desea continuar?',
        },
        toast: {
            success: 'Cuenta de conductor creada satisfactoriamente.',
            alreadyExist: 'La dirección de correo electrónico que ha introducido ya existe.',
            error: 'Se ha producido un error al procesar su solicitud, vuelva a intentarlo más tarde.',
            confirmationSuccess: 'Cuenta verificada satisfactoriamente.',
            invalidCode: '¡Vaya! Ha introducido un código no válido.',
            resendSentOTP: 'Código OTP enviado satisfactoriamente.',
            resendOTPErr: 'El código OTP no se ha enviado correctamente. Vuelva a intentarlo más tarde.',
            referralCodeInvalid: 'Código de recomendación no válido.',
            resendSignupCode:
                '¡Hola! Le acabamos de enviar otro código, revise su bandeja de entrada de correo electrónico.',
            resendLimitExceeded: 'Ha alcanzado el número máximo de intentos. Vuelva a intentarlo de nuevo más tarde.',
        },
        errors: {
            recaptchaCode: 'Verifique el código reCaptcha.',
            passwordFormat:
                'La contraseña debe incluir al menos 10 caracteres, y al menos una mayúscula, una minúscula, un carácter especial y un número.',
            securityCode: 'El código de seguridad debe tener al menos 6 dígitos',
            firstName: 'El nombre no es válido.',
            lastName: 'El apellido no es válido.',
            termsAndCondition: 'Debe leer y aceptar los Términos y condiciones para poder continuar',
        },
    },
    Payment: {
        Title: 'Configuración de pago - ##COMPANY_BRAND_NAME##: Carga rápida de VE Reino Unido',
        ChargeUnit: '(kWh)',
        description: 'Pago a ##COMPANY_BRAND_NAME##',
        PaymentType: {
            Title: 'Configuración de pago',
            SubTitle: 'Seleccione una opción de pago',
            Message: {
                selectPaymentOption: 'Seleccione una opción de pago.',
            },
            Inputs: {
                creditAccount: {
                    label: 'Cuenta de crédito',
                    label1: 'Pague mientras carga',
                },
                paymentAccount: {
                    label: 'Cuenta de prepago',
                    label1: 'Pague por adelantado y use el crédito de la cuenta para cargar',
                },
            },
            Buttons: {
                Next: {
                    text: 'Siguiente',
                },
            },
        },
        CreditPayment: {
            Title: 'Configuración de pago',
            SubTitle: 'Cuenta de crédito',
            note: 'Tenga en cuenta que al añadir una tarjeta de pago se cargará en su cuenta en ##CURRENCY## que se abonará a su saldo de cartera de ##COMPANY_BRAND_NAME##.',
            donateMessage: {
                first: 'Sí, donar',
                second: 'Para redondear el pago a',
            },
            donationNote:
                'Donación de "Redondeo" hasta los 50 céntimos más cercanos o "completar" con una cantidad fija para cada transacción de pago.',
            Inputs: {
                CardTitle: {
                    label: 'Nombre que figura en la tarjeta',
                    placeHolder: 'Nombre que figura en la tarjeta',
                    required: 'El título de la tarjeta es necesario',
                },
                CardNumber: {
                    label: 'Número de tarjeta',
                    placeHolder: '1234  1234  1234  1234',
                    invalid: 'Número de tarjeta no válido',
                    required: 'El número de la tarjeta es necesario',
                    maxlimit: 'El número de tarjeta debe ser como máximo de 20 dígitos',
                },
                Expiry: {
                    label: 'Válida hasta',
                    required: 'El mes de caducidad es necesario',
                },
                Year: {
                    label: 'Año',
                    required: 'El año de caducidad es necesario',
                },
                CSC: {
                    label: 'CVV',
                    label1: '(Código de seguridad de la tarjeta)',
                    required: 'El código de seguridad de la tarjeta es necesario',
                    placeHolder: 'CVV',
                    maxlimit: 'Introduzca un código de al menos 3 dígitos.',
                },
                Donation: {
                    label: 'Sí, ¡quiero donar!',
                },
                giftaid: {
                    label: '¿Desea habilitar una ayuda para donativos?',
                },
                RoundUp: {
                    label: 'Redondear',
                },
                TopUp: {
                    label: 'Completar',
                },
            },
            Buttons: {
                Save: {
                    text: 'Guardar',
                },
                Back: {
                    text: 'Cambiar a cuenta prepago',
                },
                editBack: {
                    text: 'Atrás',
                },
            },
            Card: {
                expire: 'Válida hasta',
                charity: 'Donación a beneficencia',
                add: 'Añadir tarjeta',
                edit: 'Editar configuración de donaciones',
                editCard: 'Editar tarjeta',
                delete: 'Eliminar',
                replace: 'Reemplazar',
                balance: 'Saldo actual:',
                Outbalance: 'Saldo pendiente:',
                mmYY: 'MM/AA',
                mm: 'MM',
                yyyy: 'AAAA',
                Pay: {
                    text: 'Pagar ahora',
                },
                Withdraw: {
                    text: 'Retirar fondos',
                },
                toast: {
                    delete: {
                        success: 'Tarjeta eliminada satisfactoriamente.',
                        error: 'Se ha producido un error al procesar su solicitud. Vuelva a intentarlo más tarde.',
                    },
                    save: {
                        success: 'Datos de tarjeta guardados satisfactoriamente.',
                        error: 'Se ha producido un error al procesar su solicitud. Vuelva a intentarlo más tarde.',
                    },
                    paynow: {
                        success: 'Pago realizado correctamente.',
                        error: 'Se ha producido un error al procesar su solicitud. Vuelva a intentarlo más tarde.',
                    },
                    edit: {
                        success: 'Datos de tarjeta actualizados satisfactoriamente.',
                        error: 'Se ha producido un error al procesar su solicitud. Vuelva a intentarlo más tarde.',
                    },
                    replace: {
                        success: 'Datos de tarjeta sustituidos satisfactoriamente.',
                        error: 'Se ha producido un error al procesar su solicitud. Vuelva a intentarlo más tarde.',
                    },
                    common: {
                        error: 'Se ha producido un error al procesar su solicitud. Vuelva a intentarlo más tarde.',
                    },
                    amount: 'No hay saldo pendiente para cargar.',
                    noSwitch: 'Liquide su saldo pendiente antes de cambiar a una cuenta de prepago',
                },
            },
            label: {
                accountFunds: 'Fondos de la cuenta',
                rewards: 'Recompensas',
                referralBonus: 'Bono de recomendación',
                currentBalance: 'Saldo actual',
                seeMore: 'Ver más',
                message: 'Mensaje',
                charityDonation: 'Donación de caridad',
                topupAmount: 'Sí, donar',
                note: 'Nota:',
                charity: 'caridad',
                card: 'tarjeta',
                donation: 'donación',
                giftaid: 'ayuda para donativos',
                noThanks: 'No, gracias',
                cancel: 'Cancelar',
            },
            error: 'Su transacción de pago está por debajo del valor mínimo de transacción ##AMOUNT',
        },
        PrePaymentDetail: {
            Title: 'Configuración de pago',
            SubTitle: 'Cuenta de prepago',
            Inputs: {
                Amount: {
                    label: 'Cantidad',
                    placeHolder: 'Cantidad',
                    required: 'Es necesario indicar la cantidad',
                    invalid: 'La cantidad debe ser superior o igual a',
                    maxLimit: '',
                    number: 'La cantidad debe ser un número',
                },
                CardNumber: {
                    label: 'Número de tarjeta',
                    placeHolder: '1234  1234  1234  1234',
                    required: 'El número de la tarjeta es necesario',
                    invalid: 'Número de tarjeta no válido',
                },
                Expiry: {
                    label: 'Válida hasta',
                    required: 'El mes de caducidad es necesario',
                },
                Year: {
                    label: 'Año',
                    required: 'El año de caducidad es necesario',
                },
                CSC: {
                    label: 'CVV',
                    label1: '(Código de seguridad de la tarjeta)',
                    required: 'El código de seguridad de la tarjeta es necesario',
                    placeHolder: 'CVV',
                    maxlimit: 'Introduzca un código de al menos 3 dígitos.',
                },
                Donation: {
                    label: 'Sí, ¡quiero donar!',
                },
                RoundUp: {
                    label: 'Redondear',
                },
                TopUp: {
                    label: 'Completar',
                },
            },
            Buttons: {
                Save: {
                    text: 'Añadir dinero',
                },
                Back: {
                    text: 'Cambiar a cuenta de crédito',
                    text1: 'Atrás',
                },
                FailBack: {
                    text: 'Volver a la cuenta',
                },
            },
            PrePayemnt: {
                text: {
                    currentBalance: 'Saldo actual',
                    OutBalance: 'Saldo pendiente',
                },
                button: {
                    add: 'Añadir dinero',
                    Withdraw: 'Retirar fondos',
                    withdrawAccountFunds: 'Retirar fondos de la cuenta',
                    viewYourRefundRequests: 'Ver solicitudes de retirada',
                },
            },
            toast: {
                canNotAddMoney:
                    'No puede añadir más fondos porque ha alcanzado el límite máximo de fondos que pueden tenerse en la cartera de ##COMPANY_BRAND_NAME##.',
                common: {
                    error: 'Se ha producido un error al procesar su solicitud. Vuelva a intentarlo más tarde.',
                },
                maxLimitForPrePayment: '¡Hola! Solo puede cargar el saldo de la cartera hasta ##MAXAMOUNT.',
                maxLimitReached:
                    '¡Hola! Solo puede cargar el saldo de la cartera hasta ##MAXAMOUNT. Ahora puede añadir como máximo ##ALLOWEDAMOUNT.',
            },
            label: {
                accountFunds: 'Fondos de la cuenta',
                rewards: 'Recompensas',
                referralBonus: 'Bono de recomendación',
                currentBalance: 'Saldo actual',
                seeMore: 'Ver más',
                billingAddress: 'Dirección de facturación',
                charityDonation: 'Donación de caridad',
                note: 'Nota:',
            },
            donationNote: 'Donación de "Redondeo" con una cantidad fija para cada transacción de pago.',
        },
        PrePaymentWithdraw: {
            Title: 'Configuración de pago',
            SubTitle: 'Cuenta de prepago',
            Inputs: {
                Amount: {
                    label: 'Cantidad',
                    placeHolder: 'Cantidad',
                    required: 'Es necesario indicar la cantidad',
                    invalid: 'La cantidad supera el saldo actual',
                },
                CardNumber: {
                    label: 'Número de tarjeta',
                    placeHolder: '1234  1234  1234  1234',
                    required: 'El número de la tarjeta es necesario',
                    invalid: 'Número de tarjeta no válido',
                    maxlimit: 'El número de tarjeta debe ser como máximo de 20 dígitos',
                },
                Expiry: {
                    label: 'Válida hasta',
                    required: 'El mes de caducidad es necesario',
                },
                Year: {
                    label: 'Año',
                    required: 'El año de caducidad es necesario',
                },
                CSC: {
                    label: 'CVV',
                    label1: '(Código de seguridad de la tarjeta)',
                    required: 'El código de seguridad de la tarjeta es necesario',
                    placeHolder: 'CVV',
                    maxlimit: 'Introduzca un código de al menos 3 dígitos.',
                },
            },
            Buttons: {
                Withdraw: {
                    text: 'Retirar fondos',
                },
                Back: {
                    text: 'Cambiar a cuenta de crédito',
                    text1: 'Atrás',
                },
                FailBack: {
                    text: 'Volver a la cuenta',
                },
            },
            PrePayemnt: {
                text: {
                    currentBalance: 'Saldo actual',
                    OutBalance: 'Saldo pendiente',
                },
                button: {
                    add: 'Añadir dinero',
                    Withdraw: 'Retirar fondos',
                },
            },
        },
        PaymentSuccess: {
            label: '¡Perfecto!',
            Successlabel: 'Transacción realizada satisfactoriamente',
            transactionId: 'ID de transacción:',
            amount: 'Cantidad:',
            warningLabel: 'Atención',
            common: {
                error: 'Se ha producido un error al procesar su solicitud. Vuelva a intentarlo más tarde.',
            },
        },
        PaymentFail: {
            label: 'La transacción ha fallado',
            failureLabel: 'La transacción ha fallado. Vuelva a intentarlo.',
        },
        PaymentProcess: {
            label: 'Espere mientras se procesa su transacción',
        },
        Model: {
            header: 'Cambiar cuenta',
            DeleteBoxHeader: 'Eliminar tarjeta',
            WithdrawBoxHeader: 'Retirar fondos',
            button: {
                yes: 'Sí',
                no: 'No',
                existingCard: 'Tarjeta existente',
                newCard: 'Nueva tarjeta',
            },
            messagePrePayment: '¿Está seguro/a de que desea cambiarse a una cuenta prepago?',
            messageCreditPayment: '¿Está seguro/a de que desea cambiarse a una cuenta de crédito?',
            msgPrePaymentSaveCard:
                'Si se cambia a una cuenta de prepago, los datos de su tarjeta se eliminarán. ¿Está seguro/a de que desea cambiarse a una cuenta prepago?',
            DeleteCardMsg: '¿Está seguro/a de que desea eliminar los datos de la tarjeta?',
            WithdrawMoneyMsg: 'Seleccione la opción de tarjeta para retirar dinero.',
        },
        charityModal: {
            yesDonate: 'Sí, donar',
        },
        Withdraw: {
            CanNotWithdrawMoneyWhileSessionIsActive:
                'No puedes retirar dinero en este momento. Espere a que se complete la sesión de carga en ejecución',
        },
    },
    termsAndCondition: {
        title: 'Términos y condiciones',
        note: 'Página en construcción.',
    },
    table: {
        data: {
            noData: 'No se han encontrado datos.',
        },
        pagination: {
            prev: 'Anterior',
            nrev: 'Siguiente',
            showing: 'Mostrando',
            pageSize: {
                ten: '10',
                fifty: '50',
                hundred: '100',
            },
        },
    },
    history: {
        headerTitle: 'Historial de cargas y pagos - ##COMPANY_BRAND_NAME##: Carga rápida de VE Reino Unido',
        title: 'Historial de cargas y pagos',
        columns: {
            chargingStation: 'Estación de carga',
            date: 'Fecha',
            address: 'Dirección',
            milesAdded: 'Millas añadidas',
            time: 'Tiempo',
            cost: 'Coste',
            energy: 'Energía',
            action: 'Acción',
            remark: 'Observaciones',
            credit: 'Crédito',
            debit: 'Débito',
            closing: 'Cerrando',
            transaction: 'ID de transacción',
            credit_debit: 'Crédito/Débito',
        },
        form: {
            charge: 'Cargando',
            payment: 'Pago',
            month: 'Mes',
        },
        label: {
            exportAll: 'Generar recibo',
        },
        button: {
            search: 'Buscar',
            reset: 'Restablecer',
        },
        message: {
            selectRecordToDownload: 'Seleccione cualquier registro para generar un recibo.',
            noChargingHistoryMessage: 'Cuando cargue su VE, su historial de cargas aparecerá aquí.',
            noPaymentHistoryMessage: 'Cuando haya completado una transacción de pago, esta se mostrará aquí.',
        },
        receipt: {
            title: 'RECIBO',
            header: {
                date: 'Fecha',
                billing_addresss: 'Dirección de facturación',
                note: 'Su recibo de ##COMPANY_BRAND_NAME## de carga de VE',
                vat_number: 'Número de IVA',
            },
            content: {
                summary: 'A continuación se muestra un resumen de sus transacciones de carga',
                table: {
                    header: {
                        station_name: 'Nombre de la estación',
                        start: 'Inicio',
                        end: 'Fin',
                        usage: 'Uso',
                        unit: 'Unidad',
                        value: 'Valor',
                        transaction_id: 'ID de transacción',
                        vatRate: 'Tipo de IVA',
                        vat: 'IVA',
                    },
                },
                total_before_vat: 'Total antes de IVA',
                total_vat: 'IVA Total',
                vat: 'IVA de',
                total_fee: 'Tarifa total',
                thanks_message: 'Gracias por cargar en la red de carga de VE de ##COMPANY_BRAND_NAME##',
                vatReceiptNotGenerated:
                    'El recibo del IVA aún no se ha generado. Vuelva a intentarlo pasadas 24 horas de la sesión de carga. Si sigue sin poder generarse, póngase en contacto con nuestro equipo de atención al cliente.',
                vatReceiptNotAssociatedWithAcc:
                    'El recibo del IVA no se ha asociado a su cuenta. Asegúrese de que esté usando las credenciales correctas para visualizar el recibo de IVA.',
            },
            footer: {
                title: '##COMPANY_BRAND_NAME## Limited es una empresa registrada en Inglaterra y Gales con número de compañía 10484882',
                address: '6 Cedarwood, Crockford Lane, Chineham Park, Basingstoke, Hampshire, RG24 8WD',
                telephone: 'T',
                email: 'E',
                note: 'Indique la fecha de la transacción y el ID de la transacción en toda la correspondencia.',
            },
        },
    },
    loyaltyPoint: {
        header: 'Recompensas - ##COMPANY_BRAND_NAME## : Carga rápida de VE Reino Unido',
        title: 'Recompensas',
        note: '*Solo puede canjear Volts a través de nuestra aplicación.',
        availableVolts: 'Volts disponibles:',
        noDataMessage: 'Cuando haya completado una sesión de carga, sus transacciones de Volts se mostrarán aquí.',
        label: {
            chargingStation: 'Estación de carga',
            date: 'Fecha',
            address: 'Dirección',
            credit: 'Crédito',
            debit: 'Débito',
            closing: 'Cerrando',
            redeemed: 'Canjeados',
            charged: 'Cargados',
            remark: 'Observaciones',
            credit_debit: 'Crédito/Débito',
            voltsRedeemed: 'Volts canjeados',
        },
    },
    withdraw: {
        subTitle: 'Cuenta de prepago',
        label: {
            maxWithdrawalBalance: 'Saldo máximo de retirada',
            note: 'Nota:',
            enterAmount: 'Introducir una cantidad',
            withdrawAmount: 'Retirar cantidad',
            expires: 'Válida hasta',
            rewardHeading: 'Introduzca el valor que desee retirar de su cuenta',
            selectCardToWithdrawAmount: 'Seleccione la tarjeta para retirar la cantidad',
            remainingAmount: 'Cantidad restante',
            accountName: 'Nombre de la cuenta',
            sortCode: 'Código de sucursal',
            accountNumber: 'Número de cuenta',
            bankDetail: 'Datos del banco',
            withdrawAmountNote:
                'Solo podrá transferir los fondos de su cuenta a la tarjeta de pago que se usó para depositar fondos en la cuenta.',
            withdrawAmountNote1: 'Tenga en cuenta que la retirada se procesará en un plazo de 14 días.',
            withdrawAmountNote2: 'Tenga en cuenta que la retirada se procesará en un plazo de 3 a 5 días.',
            accountNameNote:
                'Asegúrese de que el nombre de la cuenta del banco coincida con el nombre de la cuenta de ##COMPANY_BRAND_NAME##. De lo contrario, se rechazará la retirada.',
        },
        placeholder: {
            amount: 'Cantidad',
            accountName: 'Nombre de la cuenta',
            sortCode: 'Código de sucursal',
            accountNumber: 'Número de cuenta',
        },
        button: {
            back: 'Atrás',
            next: 'Siguiente',
            raisedRequest: 'Solicitar retirada',
            withdrawMoney: 'Retirar fondos',
            proceed: 'Continuar',
        },
        errors: {
            amount: {
                typeError: 'Introduzca una cantidad válida',
                moreThan: 'La cantidad debe ser superior a 0',
            },
            AmountIsGreaterThanwithdrawableAmount:
                'La cantidad que ha introducido es superior a la cantidad que se puede retirar',
            selectCardToWithdrawMoney: 'Seleccione cualquier tarjeta para retirar dinero.',
            canNotSelectCard:
                'El valor de retirada excede los fondos de retirada máximos disponibles para la tarjeta de pago usada. Elija otra tarjeta.',
        },
    },
    termsAndConditionListing: {
        title: 'Términos y condiciones - ##COMPANY_BRAND_NAME##: Carga rápida de VE Reino Unido',
        noteDetails: '*Es obligatorio aceptarlo para usar/crear una cuenta.',
        label: {
            referralCode: 'Código de recomendación (si lo tiene)',
            promotioncode: 'Código de promoción (si lo tiene)',
            termsAndConditions: 'Términos y condiciones.',
            welcome: 'Bienvenido/a',
            apply: 'Aplicar',
            note: 'Nota:',
            invalidCode: '¡Vaya! ¡Código no válido!',
            usedCode: 'Código ya utilizado',
            codeApplied: 'Código aplicado',
            iAggreeToTermsCondition: 'Acepto',
            reviewUpdatedCondition:
                'Revise y acepte los términos y condiciones actualizados que se proporcionan a continuación.',
            name: 'Nombre',
            accepted: 'Aceptado',
        },
        button: {
            next: 'Siguiente',
            cancel: 'Cancelar',
            accept: 'Aceptar',
        },
        errors: {
            acceptTermCondition: 'Acepte los Términos y condiciones *obligatorios para continuar.',
            invalidReferralCode:
                'El código de recomendación ##REFERRAL_CODE## introducido no es válido. Corrija (el texto distingue entre mayúsculas y minúsculas) o borre el código de recomendación para continuar.',
            invalidPromotionCode:
                'El código de promoción ##PROMO_CODE## introducido no es válido. Corrija (el texto distingue entre mayúsculas y minúsculas) o borre el código de promoción para continuar.',
            referralNotAllowed: 'Los códigos de recomendación no se pueden usar con esta promoción',
        },
        message: {
            refferalCode: {
                first: 'El código de recomendación',
                second: 'introducido no es válido. Corrija (el texto distingue entre mayúsculas y minúsculas) o borre el código de recomendación para continuar.',
            },
            promotionCode: {
                first: 'El código de promoción',
                second: 'introducido no es válido. Corrija (el texto distingue entre mayúsculas y minúsculas) o borre el código de promoción para continuar.',
            },
        },
    },
    cookiePolicy: {
        text: 'Este sitio web utiliza cookies para mejorar la experiencia del usuario. Al utilizar nuestro sitio, usted reconoce que ha leído y entiende nuestra',
        action: 'Política de cookies',
        iAccept: 'Acepto',
    },
    openSource: {
        title: 'Créditos',
    },
    refundRequest: {
        title: 'Solicitudes de retirada - ##COMPANY_BRAND_NAME##: Carga rápida de VE Reino Unido',
        header: 'Solicitudes de retirada',
        label: {
            name: 'Nombre',
            requestedDate: 'Fecha de solicitud',
            refundRequested: 'Retirada solicitada',
            account: 'Cuenta (para retirada de crédito)',
            remarks: 'Observaciones',
            transactionID: 'ID de solicitud',
            processing: 'Procesando',
            cancelled: 'Cancelado',
            declined: 'Rechazado',
            complete: 'Completo',
            date: 'Fecha',
            status: 'Estado',
            cancelRequest: 'Cancelar solicitud',
            action: 'Acción',
        },
        button: {
            search: 'Buscar',
            reset: 'Restablecer',
            yes: 'Sí',
            no: 'No',
        },
        placeholder: {
            selectStatus: 'Seleccionar estado',
        },
        message: {
            confirmCancelRequest: '¿Está seguro/a de que desea cancelar su solicitud de retirada?',
        },
        errors: {
            sortCode: {
                min: 'Introduzca un código de sucursal de al menos 6 dígitos.',
                valid: 'Introduzca un código de sucursal válido.',
            },
            accountNumber: {
                min: 'Introduzca un número de cuenta de al menos 8 dígitos.',
            },
        },
    },
    footer: {
        version: 'Versión',
        copyRightText: 'Todos los derechos reservados',
    },
    rfidCards: {
        headerTitle: 'Tarjetas RFID - ##COMPANY_BRAND_NAME##: Carga rápida de VE Reino Unido',
        title: 'Tarjetas RFID',
        label: {
            public_card_id: 'Número(s) de tarjeta',
            quantity: 'Introducir una cantidad',
            action: 'Acción',
            delete: 'Eliminar',
            cardNumber: 'Introducir número de tarjeta',
            rfid_card_unit_price: 'El precio unitario de una tarjeta RFID es',
            rfid_card_unit_price_label: 'Por tarjeta RFID',
        },
        placeholder: {
            quantity: 'Introducir una cantidad',
            public_card_id: 'Introducir número de tarjeta',
        },
        noDataMessage: 'No hay una tarjeta RFID activa en este momento',
        requestProcessDaysMessage: 'Su solicitud de tarjeta RFID se procesará en ##DAYS## días hábiles.',
        modal: {
            header: 'Solicitud de tarjeta RFID',
            body: 'Introduzca el número de tarjetas RFID requeridas. Las tarjetas se enviarán a la dirección que usted ha indicado en su cuenta. Al recibir las tarjetas deberá activarlas con su cuenta.',
        },
        addCardModal: {
            header: 'Añadir tarjeta RFID',
        },
        button: {
            proceed: 'Continuar',
            cancel: 'Cancelar',
            addNewCard: 'Añadir tarjeta RFID',
            requestCard: 'Solicitar nueva tarjeta RFID',
            delete: 'Eliminar',
            action: 'Acción',
        },
        errors: {
            quantity: {
                min: 'La cantidad debe ser superior a cero',
            },
            public_card_id: {
                valid: 'El ID de tarjeta introducido no se ha reconocido. Asegúrese de que no se han incluido espacios/caracteres especiales en el ID.',
            },
        },
        message: {
            here: 'aquí',
            deleteConfirmMessage:
                'Al eliminar esta tarjeta, se deshabilitará permanentemente y no se podrá volver a registrar. ¿Está seguro/a de que desea continuar?',
        },
    },
    giftAidmessage: 'Mensaje de ayuda para donativos',
    creditAccountDonationNote:
        'Si selecciona Sí, se aplicará una donación de ##AMOUNT## a todos los pagos siguientes. Puede cambiar sus preferencias de donación en cualquier momento haciendo clic en el botón Editar configuración de donaciones.',
    PrepaymentAccountDonationNote:
        'Si selecciona Sí, se aplicará una donación de ##AMOUNT## a esta transacción de pago. Puede cambiar sus preferencias de donación en cualquier momento haciendo clic en el botón Editar configuración de donaciones.',
    aboutUs: {
        listTitle: {
            openSourcelicenses: 'Licencias de código abierto',
        },
    },
    price_breakdown_details: {
        detailed_breakup: 'Desglose detallado',
        total_cost: 'Coste total',
        conversion_rate: 'Tasa de conversión',
    },
};
