import React, { Component } from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import * as AppActionCreators from './store/actions/app.Actions';
import { Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from './store/state/app.state';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash';
import { MainLayout } from './layout';
import LoginPage from './pages/login/LoginPage';
import { IApplicationProps } from './types/PropTypes';
import componentQueries from 'react-component-queries';
import { toast, ToastContainer } from 'react-toastify';
import amplifyConfig from './utils/amplifyConfig';

import 'react-toastify/dist/ReactToastify.css';
import FAQPage from './pages/faq/FAQPage';
import FleetFAQPage from './pages/faq/FleetFAQPage';
import PremiumSubscriptionPageHOC from './pages/premium-subscription/PremiumSubscriptionPage';
import LoginService from './services/login/login-service';
import Spinner from './layout/spinner';
import { Common } from './utils/constants';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import UserService from './services/user/user-service';
import StandardResponse from './models/standard-response';
import { IUserData as IUserProfileData } from './models/profile';
import LoginAPIService from './services/login/loginApi-service';
import TermsAndConditionListing from './pages/terms-conditions/TermsAndConditionListing';
import CookieConsent from 'react-cookie-consent';
import { IGetConfigurationData } from './models/profile/index';
import OpenSource from './pages/open-source/OpenSource';
import { ConfirmationBox } from './components/common/ConfirmationBox';
import PaymentPage from './pages/payment/PaymentPage';
import { store } from './store';
import AboutUs from './pages/about-us/AboutUs';
import { withRouter } from './components/custom-hoc/withRouter';
import StationDetailsHOC from './pages/station-details/StationDetails';
import ReferralHOC from './pages/referral/Referral';
import FleetStationDetailsHOC from './pages/station-details/FleetStationDetails';
import PartnerPromotionPageHOC from './pages/partnerPromotion/PartnerPromotionPage';
import DownloadVATReceiptHOC from './pages/downloadVATReceipt/DownloadVATReceipt';
import { companyNameReplace } from './utils';

const RefundRequestGrid = React.lazy(() => import('./pages/refund-request/RefundRequestGrid'));
const PaymentSecure = React.lazy(() => import('./pages/payment-secure/PaymentSecure'));
const ProfilePage = React.lazy(() => import('./pages/profile/profilePage'));
const HistoryPage = React.lazy(() => import('./pages/history/HistoryPage'));
const Payment = React.lazy(() => import('./pages/payment/Payment'));
const LoyaltyPointGrid = React.lazy(() => import('./pages/loyaltyPoints/LoyaltyPointGrid'));
const RFIDCardsPage = React.lazy(() => import('./pages/rfid-cards/RFIDCardsPage'));
const AcceptedTermsConditions = React.lazy(() => import('./pages/terms-conditions/AcceptedTermsAndConditions'));

Amplify.configure(amplifyConfig);

const queryStringLang = new URLSearchParams(window.location.search);
const languageInfo = store?.getState()?.LanguageInfo?.supportedLanguages;
const changelanguage = languageInfo
    ? languageInfo?.filter(
          (item) => item?.code === queryStringLang.get('langCode') || item?.code === queryStringLang.get('language'),
      )[0]
    : { code: queryStringLang.get('langCode') || queryStringLang.get('language') };
interface IAppProps extends IApplicationProps {
    breakpoint: string;
    t: TFunction;
}
interface IState {
    isOpen: boolean;
    isReady: boolean;
    cookiePolicyURL: string;
    modal: {
        showModal: boolean;
        callBack: Function | null;
        header: string;
        body: string;
    };
    showCookies: boolean;
    companyName: string;
}
class App extends Component<IAppProps & WithTranslation, IState> {
    loginService: LoginService;
    userService: UserService;
    loginApiService: LoginAPIService;
    storeState = store.getState();
    constructor(props: IAppProps & WithTranslation) {
        super(props);
        this.state = {
            isOpen: false,
            isReady:
                this.props.location.pathname.includes('/partner-promotion') ||
                this.props.location.pathname.includes('/payment/initiate'),
            cookiePolicyURL: '',
            modal: {
                showModal: false,
                header: '',
                body: '',
                callBack: null,
            },
            showCookies: false,
            companyName: '',
        };
        this.loginService = new LoginService();
        this.loginApiService = new LoginAPIService();
        this.userService = new UserService();
    }

    async componentWillMount() {
        if (localStorage.getItem(Common.OauthFedratedSignIn)) {
            toast.error(this.props.t('login.toast.cancelled'));
            localStorage.removeItem(Common.OauthFedratedSignIn);
        }
    }

    async componentDidMount() {
        if (
            !this.props.location.pathname.includes('/partner-promotion') &&
            !this.props.location.pathname.includes('/payment/initiate')
        ) {
            Hub.listen('auth', this.listener);
            await this.tryAutoSignIn();
            await this.getCookiePolicyURL();
        }
        await this.getLanguageThroughQueryparams();
        const config = await this.loginService.getAPIKey({ code: 'COMPANY_BRAND_NAME' });
        this.props.setCompanyName(config.data[0].value);
    }

    getLanguageThroughQueryparams = async () => {
        if (queryStringLang.get('langCode') || queryStringLang.get('language')) {
            this.props.changeLanguage(changelanguage);
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location && this.props.location.pathname.includes('/download-receipt')) {
            sessionStorage.setItem('vat_receipt_path', this.props.location.pathname);
        }
    }
    listener = async (data: any) => {
        switch (data.payload.event) {
            case 'signIn':
                const user = await Auth.currentAuthenticatedUser();
                const federated_account_type = user.attributes.identities
                    ? JSON.parse(user.attributes.identities)[0].providerName
                    : null;
                try {
                    const name = user && user.attributes && user.attributes.name && user.attributes.name.split(' ');
                    const objRequest: any = {
                        first_name: name && name[0] ? name[0] : ' ',
                        last_name: name && name[1] ? name[1] : ' ',
                        email: user && user.attributes && user.attributes.email,
                        provider_key: user && user.attributes && user.attributes.sub,
                        federated_account_type,
                        is_federated: federated_account_type !== null,
                        language_preference_id:
                            this.storeState?.UserData?.language?.id ||
                            this.storeState?.LanguageInfo?.defaultLanguage?.id,
                    };
                    if (federated_account_type !== null) {
                        this.checkEmailAlreadyExists(objRequest, user);
                    } else {
                        await this.loginApiService.signup(objRequest);
                    }
                } catch (err) {}
                if (user.signInUserSession.idToken.payload.identities && federated_account_type === null) {
                    await this.userService
                        .getProfile()
                        .then((res: StandardResponse<IUserProfileData>) => {
                            if (
                                res &&
                                res.Data &&
                                res.Data.status &&
                                res.Data.status.code === Common.UserStatus.CANCELLED
                            ) {
                                (this.props.logout() as any).then(() => {
                                    localStorage.setItem(Common.OauthFedratedSignIn, 'true');
                                    this.setState({ isReady: true });
                                });
                            } else {
                                this.props.login(user);
                                this.props.userDetail(res.Data);
                                this.props.setCurrencyDetails(res?.Data?.default_currency);
                                this.setState({ isReady: true });
                                if (!this.props.location?.pathname?.includes('/download-receipt')) {
                                    if (res && res.Data.terms_and_condition_required) {
                                        const referralCode = localStorage.getItem('referralCode');

                                        this.props.navigate('/TermsAndCondition', {
                                            state: { referralCode: referralCode },
                                        });
                                    } else if (
                                        res &&
                                        res.Data.is_profile_verified === false &&
                                        !this.props.location?.pathname?.includes('/terms-and-conditions')
                                    ) {
                                        if (
                                            this.props.location.pathname !== Common.PayamentPage &&
                                            this.props.location.pathname !== Common.PayamentSecure
                                        ) {
                                            this.props.navigate('/profile?redirect=true');
                                        }
                                    } else if (
                                        res &&
                                        res.Data.is_profile_verified === true &&
                                        !this.props.location?.pathname?.includes('/terms-and-conditions')
                                    ) {
                                        if (res && res.Data.payment_type === null) {
                                            if (
                                                this.props.location.pathname !== Common.PayamentPage &&
                                                this.props.location.pathname !== Common.PayamentSecure
                                            ) {
                                                this.props.navigate('/payment');
                                            }
                                        } else {
                                            this.props.navigate('/');
                                        }
                                    }
                                }
                            }
                        })
                        .catch((e) => {
                            console.error(e);
                        });
                }
                break;
            case 'signIn_failure':
                console.warn('user sign in failed');
                break;
            case 'configured':
                console.warn('the Auth module is configured');
                break;
            default:
                console.warn('Nothhing Happened');
        }
    };

    tryAutoSignIn = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const isFederate = localStorage.getItem('is_federate');
        const myReferralCode = urlParams.get('code');
        if (myReferralCode !== null && myReferralCode !== '' && !myReferralCode.includes('-')) {
            localStorage.setItem('referralCode', myReferralCode);
        }

        try {
            const currentUser = await this.loginService.getCognitoUser({
                bypassCache: true,
            });

            if (currentUser && isFederate !== 'true') {
                await this.userService
                    .getProfile()
                    .then((userDetail: StandardResponse<IUserProfileData>) => {
                        if (
                            userDetail &&
                            userDetail.Data &&
                            userDetail.Data.status &&
                            userDetail.Data.status.code === Common.UserStatus.CANCELLED
                        ) {
                            (this.props.logout() as any).then(() => {
                                localStorage.setItem(Common.OauthFedratedSignIn, 'true');
                                this.setState({ isReady: true });
                            });
                        } else {
                            if (queryStringLang.get('langCode') || queryStringLang.get('language')) {
                                this.getLanguageThroughQueryparams();
                            } else {
                                this.props.changeLanguage(userDetail?.Data?.language_preference);
                            }
                            this.props.login(currentUser);
                            this.props.userDetail(userDetail.Data);
                            this.props.setCurrencyDetails(userDetail?.Data?.default_currency);
                            this.setState({ isReady: true });
                            if (!this.props.location?.pathname?.includes('/download-receipt')) {
                                if (userDetail && userDetail.Data.terms_and_condition_required) {
                                    const referralCode = localStorage.getItem('referralCode');

                                    this.props.navigate('/TermsAndCondition', {
                                        state: { referralCode: referralCode },
                                    });
                                } else if (
                                    userDetail &&
                                    userDetail.Data.is_profile_verified === false &&
                                    !this.props.location?.pathname?.includes('/terms-and-conditions')
                                ) {
                                    if (
                                        this.props.location.pathname !== Common.PayamentPage &&
                                        this.props.location.pathname !== Common.PayamentSecure
                                    ) {
                                        this.props.navigate('/profile?redirect=true');
                                    }
                                } else if (
                                    userDetail &&
                                    userDetail.Data.is_profile_verified === true &&
                                    !this.props.location?.pathname?.includes('/terms-and-conditions')
                                ) {
                                    if (userDetail && userDetail.Data.payment_type === null) {
                                        if (
                                            this.props.location.pathname !== Common.PayamentPage &&
                                            this.props.location.pathname !== Common.PayamentSecure
                                        ) {
                                            this.props.navigate('/payment');
                                        }
                                    }
                                }
                            }
                        }
                    })
                    .catch(() => {
                        if (!(myReferralCode && window.location.pathname === '/')) {
                            this.props.logout();
                            toast.error(this.props.t('login.toast.error'));
                        }
                        this.setState({ isReady: true });
                    });
            } else {
                localStorage.removeItem('is_federate');
            }
        } catch (error) {
            if (!(myReferralCode && window.location.pathname === '/')) this.props.logout();
            this.setState({ isReady: true });
        }
    };

    getCookiePolicyURL = async () => {
        await this.userService
            .getConfigurationDetail({
                code: Common.ConfigurationKey.COOKIE_POLICY_URL,
            })
            .then((res: IGetConfigurationData) => {
                this.setState({ ...this.state, cookiePolicyURL: res.data[0]?.value });
            })
            .catch((e) => {
                if (e.response && e.response.data && e.response.data.message) {
                    toast.error(e.response.data.message);
                } else {
                    toast.error(e.toString());
                }
            });
    };

    checkEmailAlreadyExists = async (objRequest, user) => {
        objRequest.federated_account_type =
            objRequest.federated_account_type === null || objRequest.federated_account_type === undefined
                ? ''
                : objRequest.federated_account_type;
        const input = {
            email: objRequest.email,
            federated_account_type:
                objRequest.federated_account_type === 'SignInWithApple' ? 'Apple' : objRequest.federated_account_type,
        };
        await this.loginApiService
            .checkEmailAlreadyExists(input)
            .then(async (res: any) => {
                this.loginFederatedAccount(objRequest, user);
            })
            .catch((m) => {
                if (
                    m.response &&
                    m.response.status &&
                    m.response.status === 409 &&
                    m.response.data &&
                    typeof m.response.data === 'object'
                ) {
                    if (Object.keys(m.response.data).length === 0) {
                        let modalMessage = companyNameReplace(
                            this.props.t('signup.label.alreadyRegisteredWithNormalAcc'),
                        );
                        let emailString = "<span class='themeTextColor'>" + objRequest.email.toString() + '</span>';
                        modalMessage = _.replace(modalMessage, '##EMAIL##', emailString);
                        this.handleModal(modalMessage, companyNameReplace(this.props.t('signup.label.instavolt')), () =>
                            this.loginFederatedAccount(objRequest, user),
                        );
                    } else {
                        let existingEmails = m.response.data.data ? m.response.data.data : null;
                        if (!_.isEmpty(existingEmails)) {
                            let modalMessage = '';
                            modalMessage = companyNameReplace(this.props.t('signup.label.modalMessage'));
                            let emailString = "<span class='themeTextColor'>" + objRequest.email.toString() + '</span>';
                            modalMessage = _.replace(modalMessage, '##EMAIL##', emailString);
                            let accounts = '';
                            const existingEmailsLength = existingEmails.length;
                            existingEmails.forEach((account, index) => {
                                if (account.federated_account_type !== null) {
                                    if (existingEmailsLength === 1 || existingEmailsLength === index + 1) {
                                        accounts =
                                            accounts +
                                            "<span class='themeTextColor'>" +
                                            account.federated_account_type.toString() +
                                            '</span>';
                                    } else {
                                        accounts =
                                            accounts +
                                            "<span class='themeTextColor'>" +
                                            account.federated_account_type.toString() +
                                            '</span>' +
                                            ', ';
                                    }
                                } else {
                                    if (existingEmailsLength === 1 || existingEmailsLength === index + 1) {
                                        accounts =
                                            accounts +
                                            "<span class='themeTextColor'>" +
                                            companyNameReplace(this.props.t('signup.label.instavoltUserAccount')) +
                                            '</span>';
                                    } else {
                                        accounts =
                                            accounts +
                                            "<span class='themeTextColor'>" +
                                            companyNameReplace(this.props.t('signup.label.instavoltUserAccount')) +
                                            '</span>' +
                                            ', ';
                                    }
                                }
                            });
                            modalMessage = _.replace(modalMessage, '##FEDERATEDACCOUNT##', accounts);
                            this.handleModal(
                                modalMessage,
                                companyNameReplace(this.props.t('signup.label.instavolt')),
                                () => this.loginFederatedAccount(objRequest, user),
                            );
                        } else {
                            toast.error(this.props.t('login.toast.error'));
                        }
                    }
                } else if (m.response && m.response.data && m.response.data.message) {
                    toast.error(m.response.data.message);
                } else {
                    toast.error(m.toString());
                }
            })
            .finally();
    };

    loginFederatedAccount = async (objRequest, user) => {
        const downloadVATRedirect = sessionStorage.getItem('vat_receipt_path');
        try {
            await this.loginApiService.signup(objRequest);
        } catch (err) {}
        await this.userService
            .getProfile()
            .then((res: StandardResponse<IUserProfileData>) => {
                if (res && res.Data && res.Data.status && res.Data.status.code === Common.UserStatus.CANCELLED) {
                    (this.props.logout() as any).then(() => {
                        localStorage.setItem(Common.OauthFedratedSignIn, 'true');
                        this.setState({ isReady: true });
                    });
                } else {
                    if (queryStringLang.get('langCode') || queryStringLang.get('language')) {
                        this.getLanguageThroughQueryparams();
                    } else {
                        this.props.changeLanguage(res?.Data?.language_preference);
                    }
                    this.props.login(user);
                    this.props.userDetail(res.Data);
                    this.props.setCurrencyDetails(res?.Data?.default_currency);
                    this.setState({ isReady: true });
                    if (downloadVATRedirect && downloadVATRedirect !== '') {
                        sessionStorage.removeItem('vat_receipt_path');
                        this.props.navigate(downloadVATRedirect);
                    } else if (res && res.Data.terms_and_condition_required) {
                        const referralCode = localStorage.getItem('referralCode');
                        this.props.navigate('/TermsAndCondition', {
                            state: { referralCode: referralCode },
                        });
                    } else if (
                        res &&
                        res.Data.is_profile_verified === false &&
                        !this.props.location?.pathname?.includes('/terms-and-conditions')
                    ) {
                        if (
                            this.props.location.pathname !== Common.PayamentPage &&
                            this.props.location.pathname !== Common.PayamentSecure
                        ) {
                            this.props.navigate('/profile?redirect=true');
                        }
                    } else if (
                        res &&
                        res.Data.is_profile_verified === true &&
                        !this.props.location?.pathname?.includes('/terms-and-conditions')
                    ) {
                        if (!localStorage.getItem(`${Common.MARKETING_CONSENT}-${res.Data.id}`)) {
                            this.props.navigate('/profile');
                        } else if (res && res.Data.payment_type === null) {
                            if (
                                this.props.location.pathname !== Common.PayamentPage &&
                                this.props.location.pathname !== Common.PayamentSecure
                            ) {
                                this.props.navigate('/payment');
                            }
                        } else {
                            this.props.navigate('/');
                        }
                    }
                }
            })
            .catch((e) => {
                console.error(e);
            });
        this.setState({
            ...this.state,
            modal: {
                showModal: false,
                header: '',
                body: '',
                callBack: null,
            },
        });
    };

    handleModal = (body: string, header: string, callBack: Function) => {
        this.setState({
            ...this.state,
            modal: {
                body: body,
                header: header,
                callBack: callBack,
                showModal: !this.state.modal.showModal,
            },
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            modal: {
                body: '',
                header: '',
                callBack: null,
                showModal: false,
            },
        });
        (this.props.logout() as any).then(() => {
            localStorage.setItem('is_federated_login', 'true');
            this.props.navigate('/login');
        });
    };

    handleLanguageAndError = () => {
        if (!this.state.showCookies) {
            this.setState({
                showCookies: true,
            });
        }
    };

    checkAuthenticatedUser = (component) => {
        if (
            !this.props.userData ||
            !this.props.userData.authConfigs ||
            !this.props.userData.authConfigs.accessToken ||
            this.props.userData.authConfigs.accessToken === ''
        ) {
            return <Navigate replace to="/login" />;
        } else {
            return component;
        }
    };

    checkNotAuthenticatedUser = (isSignUp) => {
        if (
            this.props.userData === null ||
            (this.props.userData && this.props.userData.language && !this.props.userData.authConfigs)
        ) {
            if (isSignUp) {
                return (
                    <LoginPage
                        isSignUp={isSignUp}
                        handleLanguageAndError={this.handleLanguageAndError}
                        {...this.props}
                        key={this.props.location.key}
                    />
                );
            } else {
                return (
                    <LoginPage
                        {...this.props}
                        handleLanguageAndError={this.handleLanguageAndError}
                        key={this.props.location.key}
                    />
                );
            }
        } else if (this.props.userData.terms_and_condition_required) {
            return <Navigate replace to="/TermsAndCondition" />;
        } else if (this.props.userData.is_profile_verified === false) {
            return <Navigate replace to="/profile?redirect=true" />;
        } else if (this.props.userData.payment_type === null) {
            return <Navigate replace to="/payment" />;
        } else {
            return <Navigate replace to="/" />;
        }
    };

    render() {
        const { t } = this.props;
        return (
            <>
                {this.state.isReady && (
                    <Routes>
                        <Route path="/payment/initiate" element={<PaymentPage {...this.props} />} />
                        <Route path="/FAQ" element={<FAQPage {...this.props} />} />
                        <Route path="/fleet-faq" element={<FleetFAQPage {...this.props} />} />
                        <Route path="/open-source" element={<OpenSource {...this.props} />} />
                        <Route path="/about-us" element={<AboutUs {...this.props} />} />
                        <Route
                            path="/premium-subscription"
                            element={<PremiumSubscriptionPageHOC {...this.props} key={this.props.location.key} />}
                        />
                        <Route
                            path="/station-details/:id?"
                            element={<StationDetailsHOC {...this.props} key={this.props.location.key} />}
                        />
                        <Route
                            path="/fleet-station-details/:id?"
                            element={<FleetStationDetailsHOC {...this.props} key={this.props.location.key} />}
                        />
                        <Route
                            path="/referral/:id?"
                            element={<ReferralHOC {...this.props} key={this.props.location.key} />}
                        />
                        <Route
                            path="/partner-promotion/:id/:driver_id/:session_id?"
                            element={<PartnerPromotionPageHOC {...this.props} key={this.props.location.key} />}
                        />
                        <Route path="/login" element={this.checkNotAuthenticatedUser(false)} />
                        <Route path="/sign-up" element={this.checkNotAuthenticatedUser(true)} />

                        <Route
                            path="/TermsAndCondition"
                            element={this.checkAuthenticatedUser(
                                <TermsAndConditionListing {...this.props} key={this.props.location.key} />,
                            )}
                        />

                        <Route
                            path="/download-receipt/:session_id?/:session_primary_id?"
                            element={this.checkAuthenticatedUser(
                                <DownloadVATReceiptHOC {...this.props} key={this.props.location.key} />,
                            )}
                        />
                        <Route
                            element={
                                <MainLayout logout={this.props.logout} changeLanguage={this.props.changeLanguage} />
                            }
                        >
                            <Route
                                path="/"
                                element={this.checkAuthenticatedUser(
                                    <HistoryPage {...this.props} key={this.props.location.key} />,
                                )}
                            />
                            <Route
                                path="/Profile"
                                element={this.checkAuthenticatedUser(
                                    <ProfilePage {...this.props} key={this.props.location.key} />,
                                )}
                            />
                            <Route
                                path="/terms-and-conditions"
                                element={this.checkAuthenticatedUser(
                                    <AcceptedTermsConditions {...this.props} key={this.props.location.key} />,
                                )}
                            />

                            <Route
                                path="/payment"
                                element={this.checkAuthenticatedUser(
                                    <Payment {...this.props} key={this.props.location.key} />,
                                )}
                            />
                            <Route
                                path="/paymentsecure"
                                element={this.checkAuthenticatedUser(
                                    <PaymentSecure {...this.props} key={this.props.location.key} />,
                                )}
                            />
                            <Route
                                path="/rewards"
                                element={this.checkAuthenticatedUser(
                                    <LoyaltyPointGrid {...this.props} key={this.props.location.key} />,
                                )}
                            />
                            <Route
                                path="/refundRequests"
                                element={this.checkAuthenticatedUser(
                                    <RefundRequestGrid {...this.props} key={this.props.location.key} />,
                                )}
                            />
                            <Route
                                path="/rfid-cards"
                                element={this.checkAuthenticatedUser(
                                    <RFIDCardsPage {...this.props} key={this.props.location.key} />,
                                )}
                            />
                        </Route>
                        {/* <Redirect to="/login" /> */}
                        <Route path="/" element={<Navigate replace to="/login" />} />
                        <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                )}
                <Spinner />
                <ToastContainer />
                {!this.props.location.pathname.toLowerCase().includes('faq') &&
                    !this.props.location.pathname.toLowerCase().includes('open-source') &&
                    !this.props.location.pathname.toLowerCase().includes('premium-subscription') &&
                    !this.props.location.pathname.toLowerCase().includes('partner-promotion') &&
                    !this.props.location.pathname.toLowerCase().includes('/payment/initiate') &&
                    !this.props.location.pathname.toLowerCase().includes('/terms-and-conditions') &&
                    !this.props.location.pathname.toLowerCase().includes('/about-us') && (
                        <CookieConsent buttonText={t('cookiePolicy.iAccept')} expires={30}>
                            {t('cookiePolicy.text')}
                            <a
                                href={this.state.cookiePolicyURL}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="cookiePolicy"
                            >
                                {t('cookiePolicy.action')}
                            </a>
                        </CookieConsent>
                    )}
                <ConfirmationBox
                    t={this.props.t}
                    onCancel={this.closeModal}
                    onConfirm={this.state.modal.callBack}
                    header={this.state.modal.header}
                    bodyMessage={<div dangerouslySetInnerHTML={{ __html: this.state.modal.body }}></div>}
                    Message={this.state.modal.body}
                    body={this.state.modal.body}
                    showModel={this.state.modal.showModal}
                    closeModel={this.closeModal}
                />
            </>
        );
    }
}
const mapStateToProps = (state: AppState) => ({
    userData: state.UserData,
    errors: state.errorLog,
});
const mapDispatchtoProps = (dispatch: Dispatch) => bindActionCreators(_.assign({}, AppActionCreators), dispatch);

const query = ({ width }) => {
    if (width < 575) {
        return { breakpoint: 'xs' };
    }

    if (576 < width && width < 767) {
        return { breakpoint: 'sm' };
    }

    if (768 < width && width < 991) {
        return { breakpoint: 'md' };
    }

    if (992 < width && width < 1199) {
        return { breakpoint: 'lg' };
    }

    if (width > 1200) {
        return { breakpoint: 'xl' };
    }

    return { breakpoint: 'xs' };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchtoProps)(componentQueries(query)(withTranslation()(App as any))) as any,
);
