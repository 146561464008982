export default {
    route: {
        dashboard: 'Stjórnborð',
        article: 'Greinar',
        stationTitle: 'Upplýsingar stöðvar: ##COMPANY_BRAND_NAME## - Rapid EV Charging UK',
        or: 'EÐA',
    },
    month: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'Maí',
        jun: 'Jún',
        jul: 'Júl',
        aug: 'Ágú',
        sep: 'Sep',
        oct: 'Okt',
        nov: 'Nóv',
        dec: 'Des',
    },
    error: {
        accessDenied: 'Aðgangi hafnað',
        somethingWrong: 'Eitthvað fór úrskeiðis!',
        notFound: 'Síða fannst ekki',
    },
    navbar: {
        title: '##COMPANY_BRAND_NAME##',
        logOut: 'Skrá út',
        myProfile: 'Prófíllinn minn',
        termsConditions: 'Skilmálar',
        menu: {
            dashboard: 'Stjórnborð',
            paymentConfiguration: 'Uppsetning greiðslu',
            chargingPaymentHistory: 'Hleðslu- og greiðsluferill',
            rewards: 'Verðlaun',
            rfidCards: 'RFID-kort',
        },
    },
    login: {
        title: 'Innskráning - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
        header: 'Velkomin(n) í',
        form: {
            email: 'Tölvupóstskenni',
            password: 'Lykilorð',
        },
        placeholder: {
            region: 'Velja svæði',
            language: 'Velja tungumál',
        },
        button: {
            submit: 'Skrá inn',
            forgotPassword: 'Gleymt lykilorð?',
            signup: 'Nýskrá',
            signin: 'Skrá inn',
        },
        label: {
            logo: '##COMPANY_BRAND_NAME##',
            thirdPartyLogin: 'Eða skráðu þig inn með',
            rememberMe: 'Muna eftir mér',
            newAccount: 'Ertu ekki með reikning?',
            facebook: 'Innskráning með Facebook',
            google: 'Innskráning með Google',
            apple: 'Innskráning með Apple',
            copyright: 'Höfundaréttur ##COMPANY_BRAND_NAME## Ltd',
        },
        toast: {
            success: 'Tókst að skrá inn ökumann.',
            inValid: 'Vinsamlegast sláðu inn rétt netfang og lykilorð.',
            error: 'Villa kom upp við að vinna úr beiðninni þinni, vinsamlegast reyndu aftur síðar.',
            cancelled: 'Ógilding reikningsins þíns er í vinnslu, þú getur ekki framkvæmt eftirfarandi aðgerð(ir).',
            maximumAttempt: 'Þú hefur náð hámarksfjölda tilrauna. Vinsamlegast reyndu aftur síðar.',
            reminderMessage:
                'Vinsamlegast bættu greiðslukorti við inneignaraðganginn þinn eða bættu peningum við fyrirframgreidda aðganginn þinn til að virkja greiðslu þessa kynningarkóða.',
        },
        errors: {
            inValidEmail: 'Ógilt netfang.',
        },
    },
    forgot: {
        title: 'Gleymt lykilorð - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
        resetTitle: 'Endurstilla lykilorð - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
        header: 'Gleymdirðu lykilorðinu?',
        subHeader:
            'Sláðu inn netfangið þitt hér að neðan og við munum senda þér póst til að endurstilla lykilorðið þitt',
        resetHeader: 'Endurstilltu lykilorðið þitt.',
        resetSubHeader:
            'Vinsamlegast sláðu inn kóðann sem við sendum þér í tölvupósti og endurstilltu lykilorðið þitt.',
        supportNumber: 'Símanúmer þjónustuversins okkar',
        button: {
            back: 'Til baka',
            submit: 'Endurstilla lykilorðið mitt',
            save: 'Vista nýtt lykilorð',
        },
        form: {
            email: 'Netfang',
            code: 'Kóði',
            password: 'Lykilorð',
            confirmPassword: 'Endurstaðfestu lykilorð',
            message:
                'Sé aðgangurinn þinn gildur ættir þú að fá kóða í tölvupósti. Vinsamlegast athugaðu að hafir þú búið til ##COMPANY_BRAND_NAME## aðgang með aðgang í gegnum þjónustu líkt og Apple, Google eða Facebook getur þú ekki endurstillt lykilorðið þitt þar sem slíkt fer fram í gegnum þjónustu utanaðkomandi aðila.  Ef þú þarft á frekari aðstoð að halda skaltu vinsamlegast hafa samband við okkur í gegnum',
        },
        toast: {
            success: 'Lykilorðinu þínu hefur verið breytt.',
            error: 'Villa kom upp við að vinna úr beiðninni þinni, vinsamlegast reyndu aftur síðar.',
            inValidEmailAddress: 'Ógilt netfang.',
            invalidCode: 'Úps! Þú notaðir ógildan kóða.',
        },
        errors: {
            passwordFormat:
                'Lykilorð verður að vera 10+ stafir og innihalda að minnsta kosti einn lágstaf, einn hástaf, sérstaf og tölustaf.',
            confirmPasswordMatch: 'staðfestingarlykilorðið verður að stemma við nýja lykilorðið sem þú slóst inn.',
            verificationCodeFormat: 'Vinsamlegast sláðu inn 6 tölustafa staðfestingarkóða.',
        },
    },
    dashboard: {
        title: 'DashBoard - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
        header: 'DashBoard',
        premiumSubscription:
            '##COMPANY_BRAND_NAME## veitir premium-þjónustu. Veldu áskrift til að njóta premium-áskriftarþjónustu án endurgjalds í 3 mánuði.',
    },
    permission: {
        createArticle: 'Bæta við grein',
        editArticle: 'Breyta grein',
        save: 'Vista',
        confirm: 'Ertu viss?',
        cancel: 'Hætta við',
        update: 'Uppfæra',
    },
    profile: {
        title: 'Aðgangur - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
        header: 'Aðgangur',
        addresses: 'Heimilisföng',
        personalDetails: {
            header: 'Persónuupplýsingar',
            label: {
                firstName: 'Skírnarnafn',
                lastName: 'Föðurnafn',
                email: 'Tölvupóstskenni',
                address: 'Heimilisfang',
                billingAddress: 'Reikningsfang',
                postcode: 'Póstnúmer',
                samePersonalAddress: 'Sama og persónulegt heimilisfang',
            },
            placeholder: {
                firstName: 'Skírnarnafn',
                lastName: 'Föðurnafn',
                email: 'Tölvupóstskenni',
                address: 'Sláðu inn heimilisfangið þitt',
                billingAddress: 'Reikningsfang',
                postcode: 'Póstnúmer',
                searchAddress: 'Byrjaðu að skrifa heimilisfangið þitt til að leita',
            },
            button: {
                save: 'Vista',
                cancel: 'Hætta við',
                yes: 'Já',
                no: 'Nei',
            },
            errors: {
                email: {
                    email: 'Ógilt netfang.',
                },
                first_name: {
                    matches: 'Ógilt skírnarnafn.',
                },
                last_name: {
                    matches: 'Ógilt föðurnafn',
                },
                address: {
                    address: 'Lágmark ##MIN_LENGTH## stafir',
                    invalid: 'Ógilt heimilisfang',
                },
                billing_Address: {
                    billing_Address: 'Lágmark ##MIN_LENGTH## stafir',
                    invalid: 'Ógilt reikningsfang',
                },
            },
            model: {
                header: 'Finna heimilisfang',
                address: 'Slá inn heimilisfang',
                suggested_address: 'Tillögur um heimilisföng',
                no_data_found: 'Engin heimilisföng fundust',
                no_address_found: 'Ekkert heimilisfang fannst.',
                button: {
                    ok: 'Í LAGI',
                    cancel: 'Hætta við',
                    close: 'Loka',
                },
                toast: {
                    invalidAddress: 'Rangt póstnúmer eða heimilisfang slegin inn.',
                },
            },
        },
        addressDetails: {
            header: 'Heimilisfang',
            label: {
                address1: 'Heimilisfang',
                address2: 'Heimilisfang lína 2',
                city: 'Borg',
                county: 'Hérað',
                paymentPostcode: 'Póstnúmer',
            },
            placeholder: {
                address1: 'Heimilisfang',
                address2: 'Heimilisfang lína 2',
                city: 'Borg',
                county: 'Hérað',
            },
        },
        billingAddressDetails: {
            header: 'Reikningsfang',
            label: {
                billingaddress1: 'Heimilisfang',
                billingaddress2: 'Heimilisfang lína 2',
                billingcity: 'Borg',
                billingcounty: 'Hérað',
                samePersonalAddress: 'Sama og persónulegt heimilisfang',
            },
            placeholder: {
                billingaddress1: 'Heimilisfang',
                billingaddress2: 'Heimilisfang lína 2',
                billingcity: 'Borg',
                billingcounty: 'Hérað',
            },
        },
        marketingPreferenceDetails: {
            header: 'Markaðsstillingar',
            label: {
                receivePreference: 'Fá markaðsupplýsingar frá ##COMPANY_BRAND_NAME##',
                yes: 'Já',
                no: 'Nei',
            },
            message: 'Viltu fá markaðsupplýsingar frá ##COMPANY_BRAND_NAME##?',
        },
        langPreference: 'Valið tungumál',
        changePassword: {
            header: 'Lykilorð',
            toast: {
                success: 'Lykilorðinu þínu hefur verið breytt.',
                error: 'Úps! Núverandi lykilorð er rangt.',
                commonError: 'Villa kom upp við að vinna úr beiðninni þinni, vinsamlegast reyndu aftur síðar.',
            },
            button: {
                update: 'Uppfæra',
            },
            label: {
                current_password: 'Núverandi lykilorð',
                new_password: 'Nýtt lykilorð',
                confirm_password: 'Endurstaðfestu lykilorð',
            },
            placeholder: {
                current_password: 'Núverandi lykilorð',
                new_password: 'Nýtt lykilorð',
                confirm_password: 'Endurstaðfestu lykilorð',
            },
            errors: {
                passwordFormat:
                    'Lykilorð verður að vera 10+ stafir og innihalda að minnsta kosti einn lágstaf, einn hástaf, sérstaf og tölustaf.',
                confirmPasswordMatch: 'Staðfestingarlykilorðið verður að stemma við nýja lykilorðið sem þú slóst inn.',
            },
        },
        cancelAccount: {
            header: 'Eyða aðgangi',
            note1: 'Þú getur eytt aðganginum þínum hvenær sem er. ##COMPANY_BRAND_NAME## mun eyða öllum gögnunum þínum úr kerfinu okkar.',
            note2: 'Til að eyða aðgangi.',
            clickHere: 'Smelltu hér',
            toast: {
                success:
                    'Leitt að þú ert að fara. Við höfum byrjað á því að eyða aðganginum þínum og við munum láta þig vita í tölvupósti þegar ferlinu lýkur.',
                failed: 'Villa kom upp við að vinna úr beiðninni þinni, vinsamlegast reyndu aftur síðar.',
            },
            model: {
                title: 'Eyða aðgangi',
                body: 'Með því að eyða aðganginum þínum eyðast öll gögn úr kerfinu okkar. Þetta er ekki afturkræft. Viltu samt halda áfram?',
                button: {
                    yes: 'Já',
                    no: 'Nei',
                },
            },
        },
        limitExceedAddressError: 'Tókst ekki að finna heimilisfangið á þessari stundu',
    },
    signup: {
        header: 'Búðu til aðganginn þinn með',
        title: 'Nýskráning - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
        form: {
            firstName: 'Skírnarnafn',
            lastName: 'Föðurnafn',
            email: 'Netfang',
            password: 'Lykilorð',
            referralCode: 'Tilvísunarkóði',
            code: 'Kóði',
            terms: 'Ég samþykki',
            condition: 'Notkunarskilmálar.',
        },
        button: {
            submit: 'Nýskrá',
            signin: 'Skrá inn',
            login: 'Skrá inn',
            back: 'Til baka',
            submitOtp: 'Senda',
            resend: 'Endursenda kóða?',
        },
        label: {
            continueWith: 'Eða halda áfram með',
            accountNew: 'Áttu nú þegar aðgang?',
            otpConfirmationTitle1: 'Við vorum að senda þér tölvupóst',
            otpConfirmationTitle2: 'Skráðu þig inn með því að slá inn kóðann sem sendur var á',
            otpNonVerifiedEmailNote1:
                'Aðgangurinn þinn hefur ekki verið auðkenndur. Vinsamlegast sláðu inn kóðann sem við sendum á',
            otpNonVerifiedEmailNote2: 'til að halda áfram.',
            resendTitle: 'Fékkstu ekki tölvupóst?',
            resend: 'Endursenda',
            email: 'Netfang',
            instavolt: '##COMPANY_BRAND_NAME##',
            modalMessage:
                '##EMAIL## hefur nú þegar verið skráð á ##COMPANY_BRAND_NAME## aðgang í gegnum ##FEDERATEDACCOUNT##. Viltu halda áfram?',
            instavoltUserAccount: '##COMPANY_BRAND_NAME## Notendaaðgangur',
            alreadyRegisteredWithNormalAcc:
                '##EMAIL## hefur nú þegar verið skráð á ##COMPANY_BRAND_NAME## aðgang í gegnum ##COMPANY_BRAND_NAME## notendaaðgang. Viltu halda áfram?',
        },
        toast: {
            success: 'Aðgangur ökumanns hefur verið búinn til.',
            alreadyExist: 'Netfangið sem þú slóst inn er nú þegar til.',
            error: 'Villa kom upp við að vinna úr beiðninni þinni, vinsamlegast reyndu aftur síðar.',
            confirmationSuccess: 'Tókst að auðkenna aðgang.',
            invalidCode: 'Úps! Þú slóst inn ógildan kóða.',
            resendSentOTP: 'Tókst að senda OTP-kóða',
            resendOTPErr: 'Tókst ekki að senda OTP-kóða Vinsamlegast reyndu aftur síðar.',
            referralCodeInvalid: 'Ógildur tilvísunarkóði.',
            resendSignupCode: 'Heyrðu, við vorum að senda þér annan kóða. Vinsamlegast athugaðu tölvupóstinn þinn.',
            resendLimitExceeded: 'Þú hefur náð hámarkstilraunum. Vinsamlegast reyndu aftur síðar.',
        },
        errors: {
            recaptchaCode: 'Staðfesta recaptcha-kóða.',
            passwordFormat:
                'Lykilorð verður að vera 10+ stafir og innihalda að minnsta kosti einn lágstaf, einn hástaf, sérstaf og tölustaf.',
            securityCode: 'Öryggiskóði verður að vera að minnsta kosti 6 tölustafir',
            firstName: 'Ógilt skírnarnafn.',
            lastName: 'Ógilt föðurnafn',
            termsAndCondition: 'Lesa þarf og samþykkja notendaskilmála til að halda áfram',
        },
    },
    Payment: {
        Title: 'Greiðslustilling - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
        ChargeUnit: '(kWh)',
        description: 'Greiðsla til ##COMPANY_BRAND_NAME##',
        PaymentType: {
            Title: 'Uppsetning greiðslu',
            SubTitle: 'Vinsamlegast veldu greiðslumáta',
            Message: {
                selectPaymentOption: 'Vinsamlegast veldu greiðslumáta.',
            },
            Inputs: {
                creditAccount: {
                    label: 'Inneignaraðgangur',
                    label1: 'Greiddu þegar þú hleður',
                },
                paymentAccount: {
                    label: 'Fyrirframgreiddur aðgangur',
                    label1: 'Greiddu fyrst og notaðu inneign til að hlaða',
                },
            },
            Buttons: {
                Next: {
                    text: 'Áfram',
                },
            },
        },
        CreditPayment: {
            Title: 'Uppsetning greiðslu',
            SubTitle: 'Inneignaraðgangur',
            note: 'Vinsamlegast athugið að með því að bæta greiðslukorti við aðganginn þinn verður debetfært ##CURRENCY## sem verður kreditfært á ##COMPANY_BRAND_NAME## inneignina þína.',
            donateMessage: {
                first: 'Já, gefa',
                second: 'Námunda upphæð í',
            },
            donationNote:
                '„Námunda upp“ áheit í næstu 50 pens eða „Fylla á“ með fastri upphæð fyrir hverja greiðslufærslu.',
            Inputs: {
                CardTitle: {
                    label: 'Nafn á korti',
                    placeHolder: 'Nafn á korti',
                    required: 'Heiti korts vantar',
                },
                CardNumber: {
                    label: 'Kortanúmer',
                    placeHolder: '1234  1234  1234  1234',
                    invalid: 'Ógilt kortanúmer',
                    required: 'Kortanúmer vantar',
                    maxlimit: 'Kortanúmer verður að vera að hámarki 20 stafir',
                },
                Expiry: {
                    label: 'Gildistími',
                    required: 'Gildistíma vantar',
                },
                Year: {
                    label: 'Ár',
                    required: 'Ár vantar',
                },
                CSC: {
                    label: 'CSC',
                    label1: '(Öryggisnúmer korts)',
                    required: 'Öryggisnúmer korts vantar',
                    placeHolder: 'CSC',
                    maxlimit: 'Vinsamlegast sláðu inn að minnsta kosti 3 tölustafi.',
                },
                Donation: {
                    label: 'Já, ég vill gefa!',
                },
                giftaid: {
                    label: 'Virkja styrkveitingu?',
                },
                RoundUp: {
                    label: 'Námunda upp',
                },
                TopUp: {
                    label: 'Fylla á',
                },
            },
            Buttons: {
                Save: {
                    text: 'Vista',
                },
                Back: {
                    text: 'Skipta í fyrirframgreiddan aðgang',
                },
                editBack: {
                    text: 'Til baka',
                },
            },
            Card: {
                expire: 'Rennur út',
                charity: 'Áheit til góðgerðamála',
                add: 'Bæta við korti',
                edit: 'Breyta áheitastillingum',
                editCard: 'Breyta korti',
                delete: 'Eyða',
                replace: 'Skipta út',
                balance: 'Núverandi innistæða:',
                Outbalance: 'Eftirstandandi innistæða:',
                mmYY: 'MM/ÁÁ',
                mm: 'MM',
                yyyy: 'ÁÁÁÁ',
                Pay: {
                    text: 'Greiða núna',
                },
                Withdraw: {
                    text: 'Úttekt',
                },
                toast: {
                    delete: {
                        success: 'Tókst að eyða grein.',
                        error: 'Villa kom upp við að vinna úr beiðninni þinni. Vinsamlegast reyndu aftur síðar.',
                    },
                    save: {
                        success: 'Kortaupplýsingarnar þínar hafa verið vistaðar.',
                        error: 'Villa kom upp við úrvinnslu beiðninnar þinnar. Vinsamlegast reyndu aftur síðar.',
                    },
                    paynow: {
                        success: 'Greiðsla tókst.',
                        error: 'Villa kom upp við úrvinnslu beiðninnar þinnar. Vinsamlegast reyndu aftur síðar.',
                    },
                    edit: {
                        success: 'Kortaupplýsingarnar þínar hafa verið vistaðar.',
                        error: 'Villa kom upp við úrvinnslu beiðninnar þinnar. Vinsamlegast reyndu aftur síðar.',
                    },
                    replace: {
                        success: 'Tókst að skipta um kortaupplýsingar.',
                        error: 'Villa kom upp við úrvinnslu beiðninnar þinnar. Vinsamlegast reyndu aftur síðar.',
                    },
                    common: {
                        error: 'Villa kom upp við úrvinnslu beiðninnar þinnar. Vinsamlegast reyndu aftur síðar.',
                    },
                    amount: 'Engin eftirstandandi innistæða til að gjaldfæra.',
                    noSwitch:
                        'Vinsamlegast núllstilltu eftirstandandi innistæðu þína áður en þú skiptir yfir í fyrirframgreiddan aðgang',
                },
            },
            label: {
                accountFunds: 'Inneign aðgangs',
                rewards: 'Verðlaun',
                referralBonus: 'Tilvísunarbónus',
                currentBalance: 'Núverandi innistæða',
                seeMore: 'Sjá meira',
                message: 'Skilaboð',
                charityDonation: 'Áheit til góðgerðamála',
                topupAmount: 'Já, gefa',
                note: 'Athugið:',
                charity: 'góðgerðarmál',
                card: 'kort',
                donation: 'áheit',
                giftaid: 'giftaid',
                noThanks: 'Nei takk',
                cancel: 'Hætta við',
            },
            error: 'Greiðslufærslan þín er undir lágmarks færslugjaldinu ##AMOUNT',
        },
        PrePaymentDetail: {
            Title: 'Uppsetning greiðslu',
            SubTitle: 'Fyrirframgreiddur aðgangur',
            Inputs: {
                Amount: {
                    label: 'Upphæð',
                    placeHolder: 'Upphæð',
                    required: 'Upphæð vantar',
                    invalid: 'Upphæð verður að vera meiri en eða jafngild',
                    maxLimit: '',
                    number: 'Upphæð verður að vera tölustafir',
                },
                CardNumber: {
                    label: 'Kortanúmer',
                    placeHolder: '1234  1234  1234  1234',
                    required: 'Kortanúmer vantar',
                    invalid: 'Ógilt kortanúmer',
                },
                Expiry: {
                    label: 'Gildistími',
                    required: 'Gildistíma vantar',
                },
                Year: {
                    label: 'Ár',
                    required: 'Ár vantar',
                },
                CSC: {
                    label: 'CSC',
                    label1: '(Öryggisnúmer korts)',
                    required: 'Öryggisnúmer korts vantar',
                    placeHolder: 'CSC',
                    maxlimit: 'Vinsamlegast sláðu inn að minnsta kosti 3 tölustafi.',
                },
                Donation: {
                    label: 'Já, ég vill gefa!',
                },
                RoundUp: {
                    label: 'Námunda upp',
                },
                TopUp: {
                    label: 'Fylla á',
                },
            },
            Buttons: {
                Save: {
                    text: 'Bæta við fjármagni',
                },
                Back: {
                    text: 'Skipta í inneignaraðgang',
                    text1: 'Til baka',
                },
                FailBack: {
                    text: 'Aftur í aðgang',
                },
            },
            PrePayemnt: {
                text: {
                    currentBalance: 'Núverandi innistæða',
                    OutBalance: 'Eftirstandandi innistæða',
                },
                button: {
                    add: 'Bæta við fjármagni',
                    Withdraw: 'Úttekt',
                    withdrawAccountFunds: 'Taka út inneign aðgangs',
                    viewYourRefundRequests: 'Skoða úttektarbeiðnir',
                },
            },
            toast: {
                canNotAddMoney:
                    'Þú getur ekki bætt við innistæðu þar sem þú hefur náð hámarks innistæðu sem hægt er að geyma í ##COMPANY_BRAND_NAME# veski.',
                common: {
                    error: 'Villa kom upp við að vinna úr beiðninni þinni. Vinsamlegast reyndu aftur síðar.',
                },
                maxLimitForPrePayment: 'Heyrðu! Þú getur aðeins verið með allt að ##MAXAMOUNT innistæðu.',
                maxLimitReached:
                    'Heyrðu! Þú getur aðeins verið með allt að ##MAXAMOUNT innistæðu. Þú getur bætt við að hámarki ##ALLOWEDAMOUNT núna.',
            },
            label: {
                accountFunds: 'Inneign aðgangs',
                rewards: 'Verðlaun',
                referralBonus: 'Tilvísunarbónus',
                currentBalance: 'Núverandi innistæða',
                seeMore: 'Sjá meira',
                billingAddress: 'Reikningsfang',
                charityDonation: 'Áheit til góðgerðamála',
                note: 'Athugið:',
            },
            donationNote: 'Áheitið „Fyllt á“ með fastri upphæð fyrir hverja greiðslufærslu.',
        },
        PrePaymentWithdraw: {
            Title: 'Uppsetning greiðslu',
            SubTitle: 'Fyrirframgreiddur aðgangur',
            Inputs: {
                Amount: {
                    label: 'Upphæð',
                    placeHolder: 'Upphæð',
                    required: 'Upphæð vantar',
                    invalid: 'Upphæð er meiri en núverandi innistæða',
                },
                CardNumber: {
                    label: 'Kortanúmer',
                    placeHolder: '1234  1234  1234  1234',
                    required: 'Kortanúmer vantar',
                    invalid: 'Ógilt kortanúmer',
                    maxlimit: 'Kortanúmer verður að vera að hámarki 20 stafir',
                },
                Expiry: {
                    label: 'Gildistími',
                    required: 'Gildistíma vantar',
                },
                Year: {
                    label: 'Ár',
                    required: 'Ár vantar',
                },
                CSC: {
                    label: 'CSC',
                    label1: '(Öryggisnúmer korts)',
                    required: 'Öryggisnúmer korts vantar',
                    placeHolder: 'CSC',
                    maxlimit: 'Vinsamlegast sláðu inn að minnsta kosti 3 tölustafi.',
                },
            },
            Buttons: {
                Withdraw: {
                    text: 'Úttekt',
                },
                Back: {
                    text: 'Skipta í inneignaraðgang',
                    text1: 'Til baka',
                },
                FailBack: {
                    text: 'Aftur í aðgang',
                },
            },
            PrePayemnt: {
                text: {
                    currentBalance: 'Núverandi innistæða',
                    OutBalance: 'Eftirstandandi innistæða',
                },
                button: {
                    add: 'Bæta við fjármagni',
                    Withdraw: 'Úttekt',
                },
            },
        },
        PaymentSuccess: {
            label: 'Þetta tókst!',
            Successlabel: 'Færsla tókst',
            transactionId: 'Færsluauðkenni:',
            amount: 'Upphæð:',
            warningLabel: 'Viðvörun',
            common: {
                error: 'Villa kom upp við að vinna úr beiðninni þinni. Vinsamlegast reyndu aftur síðar.',
            },
        },
        PaymentFail: {
            label: 'Færsla mistókst',
            failureLabel: 'Færslan þín mistókst. Vinsamlega reyndu aftur.',
        },
        PaymentProcess: {
            label: 'Vinsamlegast hinkraðu á meðan unnið er úr færslunni þinni',
        },
        Model: {
            header: 'Skipta um aðgang',
            DeleteBoxHeader: 'Eyða korti',
            WithdrawBoxHeader: 'Úttekt',
            button: {
                yes: 'Já',
                no: 'Nei',
                existingCard: 'Núverandi kort',
                newCard: 'Nýtt kort',
            },
            messagePrePayment: 'Ertu viss um að þú viljir skipta í fyrirframgreiddan aðgang?',
            messageCreditPayment: 'Ertu viss um að þú viljir skipta í inneignaraðgang?',
            msgPrePaymentSaveCard:
                'Með því að skipta yfir í fyrirframgreiddan aðgang verður kortaupplýsingunum þínum eytt. Ertu viss um að þú viljir skipta í fyrirframgreiddan aðgang?',
            DeleteCardMsg: 'Ertu viss um að þú viljir eyða kortinu þínu?',
            WithdrawMoneyMsg: 'Vinsamlegast veldu kort til að taka út peninga.',
        },
        charityModal: {
            yesDonate: 'Já, gefa',
        },
        Withdraw: {
            CanNotWithdrawMoneyWhileSessionIsActive:
                'Þú getur ekki tekið út peninga á þessari stundu. Vinsamlegast bíddu þar til hlaupandi hleðslulotunni lýkur',
        },
    },
    termsAndCondition: {
        title: 'Skilmálar',
        note: 'Síðan er í vinnslu.',
    },
    table: {
        data: {
            noData: 'Engin gögn fundust.',
        },
        pagination: {
            prev: 'Fyrri',
            nrev: 'Áfram',
            showing: 'Sýni',
            pageSize: {
                ten: '10',
                fifty: '50',
                hundred: '100',
            },
        },
    },
    history: {
        headerTitle: 'Hleðslu- og greiðslusaga- ##COMPANY_BRAND_NAME## Rapid EV Charging UK',
        title: 'Hleðslu- og greiðsluferill',
        columns: {
            chargingStation: 'Hleðslustöð',
            date: 'Dagsetning',
            address: 'Heimilisfang',
            milesAdded: 'Vegalengd bætt við',
            time: 'Tími',
            cost: 'Kostnaður',
            energy: 'Orka',
            action: 'Aðgerð',
            remark: 'Athugasemdir',
            credit: 'Kredit',
            debit: 'Debet',
            closing: 'Lokar',
            transaction: 'Auðkenni færslu',
            credit_debit: 'Kredit/debet',
        },
        form: {
            charge: 'Hleð',
            payment: 'Greiðsla',
            month: 'Mánuður',
        },
        label: {
            exportAll: 'Búa til kvittun',
        },
        button: {
            search: 'Leita',
            reset: 'Endurstilla',
        },
        message: {
            selectRecordToDownload: 'Vinsamlegast veldu hvaða skrá sem er til að búa til kvittun.',
            noChargingHistoryMessage: 'Þegar þú hleður rafbílinn þinn birtist hleðslusagan þín hér.',
            noPaymentHistoryMessage: 'Greiðslufærsla birtist hér þegar þú hefur lokið við greiðslu',
        },
        receipt: {
            title: 'KVITTUN',
            header: {
                date: 'Dagsetning',
                billing_addresss: 'Reikningsfang',
                note: '##COMPANY_BRAND_NAME## EV-hleðslukvittunin þín',
                vat_number: 'VSK-númer',
            },
            content: {
                summary: 'Yfirlit yfir hleðslufærslurnar þínar eru hér að neðan',
                table: {
                    header: {
                        station_name: 'Heiti stöðvar',
                        start: 'Upphaf',
                        end: 'Lok',
                        usage: 'Notkun',
                        unit: 'Eining',
                        value: 'Verðgildi',
                        transaction_id: 'Auðkenni færslu',
                        vatRate: 'VSK-gjald',
                        vat: 'VSK',
                    },
                },
                total_before_vat: 'Samtals fyrir VSK',
                total_vat: 'VSK samtals',
                vat: 'VSK',
                total_fee: 'Samtals gjald',
                thanks_message: 'Takk fyrir að hlaða í ##COMPANY_BRAND_NAME## EV-hleðslukerfinu',
                vatReceiptNotGenerated:
                    'VSK-kvittun hefur ekki verið búin til enn. Vinsamlegast prófaðu aftur 24 klst eftir að hleðslulotu lýkur. Hafðu samband við þjónustuver ef þú átt enn í erfiðleikum.',
                vatReceiptNotAssociatedWithAcc:
                    'VSK-kvittun hefur ekki verið tengd við aðganginn þinn. Vinsamlegast gakktu úr skugga um að þú sért að nota rétt skilríki til að skoða VSK-kvittunina.',
            },
            footer: {
                title: '##COMPANY_BRAND_NAME## hf er fyrirtæki skráð í Englandi og Wales, fyrirtækjanúmer 10484882',
                address: '6 Cedarwood, Crockford Lane, Chineham Park, Basingstoke, Hampshire, RG24 8WD',
                telephone: 'T',
                email: 'E',
                note: 'Vinsamlegast látið dagsetningu og auðkenni færslu fylgja í öllum samskiptum.',
            },
        },
    },
    loyaltyPoint: {
        header: 'Umbun - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
        title: 'Verðlaun',
        note: '*Þú getur aðeins innleyst volt í gegnum appið okkar.',
        availableVolts: 'Volt í boði:',
        noDataMessage: 'Þegar þú hefur lokið við hleðslulotu munu volt-færslurnar þínar birtast hér.',
        label: {
            chargingStation: 'Hleðslustöð',
            date: 'Dagsetning',
            address: 'Heimilisfang',
            credit: 'Kredit',
            debit: 'Debet',
            closing: 'Lokar',
            redeemed: 'Innleyst',
            charged: 'Hlaðið',
            remark: 'Athugasemdir',
            credit_debit: 'Kredit/debet',
            voltsRedeemed: 'Innleyst volt',
        },
    },
    withdraw: {
        subTitle: 'Fyrirframgreiddur aðgangur',
        label: {
            maxWithdrawalBalance: 'Hámarks úttektarinneign',
            note: 'Athugið:',
            enterAmount: 'Slá inn upphæð',
            withdrawAmount: 'Upphæð úttektar',
            expires: 'Rennur út',
            rewardHeading: 'Vinsamlegast sláðu inn upphæðina sem þú vilt taka út af aðganginum þínum',
            selectCardToWithdrawAmount: 'Veldu kort til að taka út upphæðina',
            remainingAmount: 'Eftirstandandi upphæð',
            accountName: 'Heiti aðgangs',
            sortCode: 'Flokkunarkóði',
            accountNumber: 'Reikningsnúmer',
            bankDetail: 'Bankaupplýsingar',
            withdrawAmountNote:
                'Þú getur aðeins millifært inneign af aðganginum þínum inn á greiðslukortið sem notað var til að leggja inn á aðganginn.',
            withdrawAmountNote1: 'Vinsamlegast athugaðu að unnið verður úr úttektinni þinni innan 14 virkra daga.',
            withdrawAmountNote2: 'Vinsamlegast athugaðu að unnið verður úr úttektinni þinni innan 3 til 5 virkra daga.',
            accountNameNote:
                ' Vinsamlegast gakktu úr skugga um að nafn bankans sé hið sama og ##COMPANY_BRAND_NAME## aðgangsins þíns, annars verður úttektinni hafnað.',
        },
        placeholder: {
            amount: 'Upphæð',
            accountName: 'Heiti aðgangs',
            sortCode: 'Flokkunarkóði',
            accountNumber: 'Reikningsnúmer',
        },
        button: {
            back: 'Til baka',
            next: 'Áfram',
            raisedRequest: 'Senda inn úttektarbeiðni',
            withdrawMoney: 'Úttekt',
            proceed: 'Halda áfram',
        },
        errors: {
            amount: {
                typeError: 'Sláðu inn gilda upphæð',
                moreThan: 'Upphæð verður að vera hærri en 0',
            },
            AmountIsGreaterThanwithdrawableAmount: 'Upphæðin sem þú valdir er hærri en upphæð sem hægt er að taka út',
            selectCardToWithdrawMoney: 'Veldu hvaða kort sem er til að taka út peninga.',
            canNotSelectCard:
                'Úttektarinneignin er hærri en tiltæk hámarks úttektar upphæð fyrir kortið sem er valið. Vinsamlegast veldu annað kort',
        },
    },
    termsAndConditionListing: {
        title: 'Notkunarskilmálar - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
        noteDetails: '*Nauðsynlegt er að samþykkja til að nota/búa til aðgang.',
        label: {
            referralCode: 'Tilvísunarkóði (ef þú ert með kóða)',
            promotioncode: 'Kynningarkóði (ef þú ert með kóða)',
            termsAndConditions: 'Notkunarskilmálar.',
            welcome: 'Velkomin(n)',
            apply: 'Bæta við',
            note: 'Athugið:',
            invalidCode: 'Úps! Rangur kóði!',
            usedCode: 'Kóði hefur nú þegar verið notaður',
            codeApplied: 'Kóða bætt við',
            iAggreeToTermsCondition: 'Ég samþykki',
            reviewUpdatedCondition: 'Vinsamlegast farið yfir og samþykkið uppfærða notkunarskilmála hér að neðan.',
            name: 'Nafn',
            accepted: 'Samþykkt',
        },
        button: {
            next: 'Áfram',
            cancel: 'Hætta við',
            accept: 'Samþykkja',
        },
        errors: {
            acceptTermCondition: 'Vinsamlegast samþykkið * nauðsynlega notkunarskilmála til að halda áfram.',
            invalidReferralCode:
                'Tilvísunarkóðinn #REFERRAL_CODE## er ekki gildur. Vinsamlegast leiðréttu (hástafir og lágstafir skipta máli) eða eyddu tilvísunarkóðanum til að halda áfram.',
            invalidPromotionCode:
                'Kynningarkóðinn #PROMO_CODE## er ekki gildur. Vinsamlegast leiðréttu hann (hástafir og lágstafir skipta máli) eða fjarlægðu kynningarkóðann til að halda áfram.',
            referralNotAllowed: 'Ekki er hægt að nota tilvísunarkóða fyrir þessa kynningu',
        },
        message: {
            refferalCode: {
                first: 'Tilvísunarkóði',
                second: 'sem sleginn var inn er ógildur Vinsamlegast leiðréttu (hástafir og lágstafir skipta máli) eða eyddu tilvísunarkóðanum til að halda áfram.',
            },
            promotionCode: {
                first: 'Kynningarkóðinn',
                second: 'sem sleginn var inn er ógildur. Vinsamlegast leiðréttu (hástafir og lágstafir skipta máli) eða fjarlægðu kynningarkóðann til að halda áfram.',
            },
        },
    },
    cookiePolicy: {
        text: 'Þessi vefsíða notar vafrakökur til að bæta upplifun notenda. Með því að nota síðuna okkar staðfestir þú að þú hafir lesið og skilið',
        action: 'Vafrastefnuna okkar',
        iAccept: 'Ég samþykki',
    },
    openSource: {
        title: 'Inneign',
    },
    refundRequest: {
        title: 'Úttektar beiðnir - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
        header: 'Úttektarbeiðnir',
        label: {
            name: 'Nafn',
            requestedDate: 'Umbeðin dagsetning',
            refundRequested: 'Óskað eftir úttekt',
            account: 'Reikningur (til úttektar)',
            remarks: 'Athugasemdir',
            transactionID: 'Auðkenni beiðni',
            processing: 'Í vinnslu',
            cancelled: 'Hætt við',
            declined: 'Hafnað',
            complete: 'Lokið',
            date: 'Dagsetning',
            status: 'Staða',
            cancelRequest: 'Hætta við beiðni',
            action: 'Aðgerð',
        },
        button: {
            search: 'Leita',
            reset: 'Endurstilla',
            yes: 'Já',
            no: 'Nei',
        },
        placeholder: {
            selectStatus: 'Velja stöðu',
        },
        message: {
            confirmCancelRequest: 'Ertu viss um að þú viljir hætta við úttektarbeiðnina?',
        },
        errors: {
            sortCode: {
                min: 'Vinsamlegast sláðu inn að minnsta kosti 6 tölustafi.',
                valid: 'Vinsamlegast sláðu inn gildan flokkunarkóða.',
            },
            accountNumber: {
                min: 'Vinsamlegast sláðu inn að lágmarki 8 tölustafa reikningsnúmer.',
            },
        },
    },
    footer: {
        version: 'Útgáfa',
        copyRightText: 'Allur réttur áskilinn',
    },
    rfidCards: {
        headerTitle: 'RFID-kort - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
        title: 'RFID-kort',
        label: {
            public_card_id: 'Kortanúmer:',
            quantity: 'Sláðu inn upphæð',
            action: 'Aðgerð',
            delete: 'Eyða',
            cardNumber: 'Sláðu inn kortanúmer',
            rfid_card_unit_price: 'Einingaverð eins RFID-korts er',
            rfid_card_unit_price_label: 'Bæta við RFID-korti',
        },
        placeholder: {
            quantity: 'Sláðu inn upphæð',
            public_card_id: 'Sláðu inn kortanúmer',
        },
        noDataMessage: 'Engin virk RFID-kort eins og er',
        requestProcessDaysMessage: 'Unnið verður úr RFID-kortabeiðninni þinni innan ##DAYS## virkra daga.',
        modal: {
            header: 'RFID-kortabeiðnir',
            body: 'Vinsamlegast sláðu inn nauðsynlegan fjölda RFID-korta. Kortin verða send á heimilisfangið sem þú hefur skráð á aðganginn þinn. Þegar þú færð kortin afhent verðum við að virkja þau fyrir aðganginn þinn.',
        },
        addCardModal: {
            header: 'Bæta við RFID-korti',
        },
        button: {
            proceed: 'Halda áfram',
            cancel: 'Hætta við',
            addNewCard: 'Bæta við RFID-korti',
            requestCard: 'Óska eftir nýju RFID-korti',
            delete: 'Eyða',
            action: 'Aðgerð',
        },
        errors: {
            quantity: {
                min: 'Fjöldi verður að vera meiri en núll',
            },
            public_card_id: {
                valid: 'Kortið sem slegið var inn þekkist ekki. Vinsamlegast gakktu úr skugga um að bil/sérstafir séu ekki í auðkenninu.',
            },
        },
        message: {
            here: 'hér',
            deleteConfirmMessage:
                'Með því að eyða þessu korti verður það gert óvirkt og ekki verður hægt að skrá það aftur. Ertu viss um að þú viljir skrá þig út?',
        },
    },
    giftAidmessage: 'skilaboð styrkveitingar',
    creditAccountDonationNote:
        'Með því að velja Já bætist ##AMOUNT## styrkur við allar greiðslur. Þú getur breytt styrkupphæðinni þinni hvenær sem er með því að smella á hnappinn â€œBreyta áheitsstillingumâ€.',
    PrepaymentAccountDonationNote:
        'Með því að velja Já bætist ##AMOUNT## stuðningur við þessa greiðslufærslu. Þú getur breytt styrkupphæðinni þinni hvenær sem er með því að smella á hnappinn â€œBreyta áheitsstillingumâ€.',
    aboutUs: {
        listTitle: {
            openSourcelicenses: 'Opið aðgengi',
        },
    },
    price_breakdown_details: {
        detailed_breakup: 'Nákvæm sundurliðun',
        total_cost: 'Samtals kostnaður',
        conversion_rate: 'Gengi',
    },
};
